import { DictionaryEntry } from '../../../models/Dictionary';

export function sortBy(sortKey: keyof DictionaryEntry, sortValue: string, sortCollection: DictionaryEntry[]) {
  const sortedCollection = sortCollection.sort((a: DictionaryEntry, b: DictionaryEntry) => {
    const keyValue = sortValue.toLowerCase().trim();
    const isMatchA = a[sortKey].toLowerCase().startsWith(keyValue);
    const isMatchB = b[sortKey].toLowerCase().startsWith(keyValue);

    if ((isMatchA && !isMatchB) || (!isMatchA && isMatchB)) {
      return isMatchA ? -1 : 1;
    }

    return a[sortKey].localeCompare(b[sortKey]);
  });

  return sortedCollection;
}
