import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactNode } from 'react';
import './EntryTable.scss';

type EntryTableProps = {
  tableBody: ReactNode;
};

const TableColumns = () => {
  return (
    <TableRow>
      <TableCell>ORIGINAL</TableCell>
      <TableCell>TYP</TableCell>
      <TableCell>ADAPTION</TableCell>
      <TableCell />
    </TableRow>
  );
};

export default function EntryTable({ tableBody }: EntryTableProps) {
  return (
    <TableContainer className="entryTable-container">
      <div className="entryTable-subContainer">
        <Table>
          <TableHead className="entryTable-header-fixed">
            <TableColumns />
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}
