import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import { withInitializedUser } from '../../components/InitializedUser/WithInitializedUser';
import { NotificationService } from '../../components/Notifications/Notifications';
import { useSearchDictionaryEntries } from '../../hooks/useSearchDictionaryEntries/useSearchDictionaryEntries';
import { DictionaryEntry } from '../../models/Dictionary';
import CreateEntryModal from './components/CreateEntryModal/CreateEntryModal';
import EntryTable from './components/EntryTable/EntryTable';
import EntryTableRow from './components/EntryTableRow/EntryTableRow';
import SearchBar from './components/SearchBar/SearchBar';
import './Dictionary.scss';
import { sortBy } from './helpers/helpers';

export const Dictionary = () => {
  const [isSearchDictionaryHookEnabled, setIsSearchDictionaryHookEnabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedEntries, setSearchedEntries] = useState<DictionaryEntry[]>([]);
  const [isOpenCreateEntryModal, setIsOpenCreateEntryModal] = useState(false);

  const sortedByExactMatchSearchedEntries = sortBy('original', searchTerm, searchedEntries);

  const {
    isFetching: isFetchingSearchResults,
    refetch: refetchSearchResults,
    isSuccess,
    isError,
    error,
    data: entries,
  } = useSearchDictionaryEntries({
    searchTerm,
    isSearchDictionaryHookEnabled,
    setSearchedEntries,
    setIsSearchDictionaryHookEnabled,
  });

  const onResetSearch = () => {
    setSearchTerm('');
    setSearchedEntries([]);
  };

  useEffect(() => {
    if (isSuccess) {
      setSearchedEntries(entries);
      setIsSearchDictionaryHookEnabled(false);
    }
  }, [isSuccess, entries]);

  useEffect(() => {
    if (isError) {
      NotificationService.error(error.message);
    }
  }, [isError, error?.message]);

  return (
    <section className="dictionaryPage">
      <div className="dictionaryPage--container">
        <Button className="createEntryModal--open-btn" onClick={() => setIsOpenCreateEntryModal(true)}>
          <PlaylistAddIcon /> NEUEN EINTRAG HINZUFÜGEN
        </Button>
        <CreateEntryModal
          isOpen={isOpenCreateEntryModal}
          onClose={setIsOpenCreateEntryModal}
          searchTerm={searchTerm}
          refetchSearchResults={refetchSearchResults}
        />
        <div className="dictionaryPage--header" title="dictionaryPage--title">
          Global Dictionary
        </div>
        <SearchBar
          isFetchingSearchResults={isFetchingSearchResults}
          searchTerm={searchTerm}
          onResetSearch={onResetSearch}
          setSearchTerm={setSearchTerm}
          setIsSearchDictionaryHookEnabled={setIsSearchDictionaryHookEnabled}
          countSearchedEntries={searchedEntries.length}
        />
        {sortedByExactMatchSearchedEntries.length !== 0 && !isFetchingSearchResults && (
          <EntryTable
            tableBody={sortedByExactMatchSearchedEntries.map((entry, index) => (
              <EntryTableRow key={index} index={index} entry={entry} refetchSearchResults={refetchSearchResults} />
            ))}
          />
        )}
      </div>

      <Footer />
    </section>
  );
};

export default withInitializedUser(Dictionary);
