import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { NotificationService } from '../../../components/Notifications/Notifications';

import { BRAND } from '../../../models/Article';
import { createArticle, CreateArticleParams } from '../../../services/ArticleService';
import { useArticlesContext } from '../store/ArticlesContext';
import './ArticleForm.scss';

interface ComponentProps {
  brand: BRAND;
  onSubmitSuccess: () => void;
}
const ArticleForm = ({ brand, onSubmitSuccess }: ComponentProps) => {
  const { isFormOpen, toggleNewArticleForm } = useArticlesContext();
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    text: '',
    title: '',
  });

  const handleFormUpdate = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const resetFormData = () =>
    setFormData({
      id: '',
      text: '',
      title: '',
    });

  const handleSubmit = () => {
    setErrorMessage(null);

    const article: CreateArticleParams = {
      externalId: formData.id,
      brand,
      text: formData.text,
      title: formData.title,
    };

    return trackPromise(
      createArticle(article)
        .then(() => {
          NotificationService.success(`Successfully submitted article job with id ${formData.id}`);
          resetFormData();
          setErrorMessage(null);
          toggleNewArticleForm(false);
          onSubmitSuccess();
        })
        .catch((error) => setErrorMessage(error))
    );
  };

  const handleClose = () => {
    resetFormData();
    toggleNewArticleForm(false);
  };

  const isFormValid = useMemo(() => {
    const { id, text } = formData;
    return !!id && !!text;
  }, [formData]);

  const errorMessageBox = errorMessage ? (
    <Alert className="vendorCreation-error" severity="error">
      {errorMessage}
    </Alert>
  ) : null;

  return (
    <Dialog data-testid="articleFormDialog" open={isFormOpen} onClose={handleClose}>
      <DialogContent>
        <section className="article-form" data-testid="articleForm-formFields">
          <h3>Artikel Informationen</h3>
          <p>Bitte geben Sie die folgenden Informationen an, um den Artikel anzulegen.</p>
          <TextField
            data-testid="articleForm-input-id"
            variant="outlined"
            required
            className="id"
            label="Artikel ID"
            value={formData.id}
            onChange={(e) => handleFormUpdate('id', e.target.value)}
            inputProps={{ 'data-testid': 'articleForm-input-externalId' }}
          />
          <TextField
            data-testid="articleForm-input-title"
            variant="outlined"
            className="title"
            label="Artikel Headline"
            value={formData.title}
            onChange={(e) => handleFormUpdate('title', e.target.value)}
            inputProps={{ 'data-testid': 'articleForm-input-articleHeadline' }}
          />
          <TextareaAutosize
            data-testid="articleForm-input-text"
            className="text"
            minRows={10}
            required
            placeholder="Artikel Teaser-Text und Fließtext einfügen"
            value={formData.text}
            onChange={(e) => handleFormUpdate('text', e.target.value)}
          />
          {errorMessageBox}
        </section>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button
          disabled={!isFormValid}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className="submitButton"
          data-testid="articleForm-submitBtn"
        >
          SYNTHETISIEREN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleForm;
