import { DictionaryEntryType } from '../../../../models/Dictionary';

export type NewEntry = {
  original: string;
  adaption: string;
  type: DictionaryEntryType | string;
  error?: string | Error;
};

export const MIN_NUMBER_ENTRIES = 3;
export const MAX_NUMBER_ENTRIES = 7;

export type CreateEntryActions = {
  [id: string]: string;
};
export enum CREATE_ENTRY_ACTIONS {
  INIT = 'INIT',
  ADD_NEW_ENTRY = 'ADD_NEW_ENTRY',
  REMOVE_CREATED_ENTRY = 'REMOVE_CREATED_ENTRY',
  ADD_ERROR_TO_ENTRY = 'ADD_ERROR_TO_ENTRY',
  CLEAR_ENTRY = 'CLEAR_ENTRY',
  UPDATE_ENTRY = 'UPDATE_ENTRY',
}

export const newEntry: NewEntry = {
  original: '',
  adaption: '',
  type: '',
};

export const isCompleteEntry = (entry: NewEntry) => {
  const { original, adaption, type } = entry;
  return original !== '' && adaption !== '' && type !== '';
};

export const isEmptyEntry = (entry: NewEntry) => {
  const { original, adaption, type } = entry;
  return original === '' && adaption === '' && type === '';
};

export const isInitNewEntries = (entries: NewEntry[]) => {
  return entries.map((entry) => isEmptyEntry(entry)).every((v) => v);
};

export const isSomeCompleteNewEntries = (entries: NewEntry[]) => {
  return entries.map((entry) => isCompleteEntry(entry)).some((v) => v);
};

export const initialEntries = [...Array(MIN_NUMBER_ENTRIES)].map((_) => newEntry);

export type Payload = {
  index?: number;
  name?: string;
  value?: string | unknown;
  original?: string;
  error?: string | Error;
};

export function createEntryModalReducer(state: NewEntry[], action: { type: CREATE_ENTRY_ACTIONS; payload?: Payload }) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ENTRY_ACTIONS.INIT:
      return [...initialEntries];
    case CREATE_ENTRY_ACTIONS.ADD_NEW_ENTRY:
      if (state.length < MAX_NUMBER_ENTRIES) {
        return [...state, newEntry];
      } else {
        return state;
      }
    case CREATE_ENTRY_ACTIONS.REMOVE_CREATED_ENTRY:
      return state.map((entry) => {
        if (entry.original !== payload?.original) {
          return entry;
        }
        return newEntry;
      });
    case CREATE_ENTRY_ACTIONS.ADD_ERROR_TO_ENTRY:
      return state.map((entry) => {
        if (entry.original === payload?.original && isCompleteEntry(entry)) {
          return { ...entry, error: payload?.error };
        }
        return entry;
      });
    case CREATE_ENTRY_ACTIONS.CLEAR_ENTRY:
      return state.map((entry, index) => {
        if (index === payload?.index) {
          return newEntry;
        }
        return entry;
      });
    case CREATE_ENTRY_ACTIONS.UPDATE_ENTRY:
      return state.map((entry, index) => {
        if (index === payload?.index) {
          const entryWithoutError = entry;
          delete entryWithoutError.error;
          return {
            ...entryWithoutError,
            // Have to comeback to this later to refactor
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
            [payload?.name!]: payload?.value,
          };
        }
        return entry;
      });
    default:
      throw new Error();
  }
}
