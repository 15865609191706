import { useState } from 'react';
import araVoicesLogo from '../../assets/Logos/araVoicesLogo.png';

export enum NOTIFICATION_PERMISSION {
  DEFAULT = 'default',
  GRANTED = 'granted',
  DENIED = 'denied',
}

const isNotificationSupported = !!window.Notification;

interface NotificationData {
  title: string;
  body: string;
  url: string;
}

const useBrowserNotification = () => {
  const [notificationStatus, setNotificationStatus] = useState<NotificationPermission | null>(() => {
    return !isNotificationSupported ? null : Notification.permission;
  });

  const enableNotification = async () => {
    if (!isNotificationSupported || notificationStatus === NOTIFICATION_PERMISSION.GRANTED) {
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      setNotificationStatus(permission);
    } catch (error) {
      console.error('Failed to request permission', error);
      //fallback to using Callback
      Notification.requestPermission((permission) => setNotificationStatus(permission));
    }
  };

  const sendNotification = ({ title, body, url }: NotificationData) => {
    if (!isNotificationSupported) {
      return;
    }

    if (notificationStatus === NOTIFICATION_PERMISSION.DEFAULT) {
      enableNotification();
      return;
    }

    if (notificationStatus === NOTIFICATION_PERMISSION.GRANTED) {
      const notification = new Notification(title, {
        body,
        icon: araVoicesLogo,
        tag: 'AraVoicesNotification',
        renotify: true,
      });

      notification.onclick = (event) => {
        event.preventDefault();
        window.open(url, '_blank');
      };
    }
  };

  return { notificationStatus, sendNotification, enableNotification };
};

export default useBrowserNotification;
