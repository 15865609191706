import Close from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { composeArticleIdTextInput } from '../../helpers/Helpers';
import { ArticleListHeaderProps } from '../../pages/ArticleLandingPage/ArticleListHeader/ArticleListHeader';
import { useArticlesContext } from '../../pages/ArticleLandingPage/store/ArticlesContext';
import { getArticleById } from '../../services/ArticleService';
import { useAuthContext } from '../../services/Auth/AuthContext';
import { NotificationService } from '../Notifications/Notifications';
import './ArticleSearch.scss';

export const ArticleSearch = ({ stopPollingForUpdate, onClearSearch }: ArticleListHeaderProps) => {
  const { brand } = useAuthContext();
  const [searchInput, setSearchInput] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);

  const ref = useRef<boolean>(false);

  const { handleArticleSearch } = useArticlesContext();
  const handleSearch = () => {
    setSearching(true);

    const articleId = composeArticleIdTextInput(searchInput, brand);
    if (!articleId.startsWith(`${brand}___`)) {
      NotificationService.error('Artikel-ID muss mit der richtigen Brand beginnen');
      setSearching(false);
      return;
    }

    trackPromise(getArticleById(articleId))
      .then((res) => {
        handleArticleSearch(res);
      })
      .catch((error) => {
        NotificationService.error(error);
      })
      .finally(() => {
        stopPollingForUpdate();
        setSearching(false);
      });
  };

  const clearSearch = () => {
    setSearchInput('');
  };

  useEffect(() => {
    if (ref.current && searchInput.length === 0) {
      onClearSearch();
    }
    ref.current = true;
  }, [ref, searchInput]);

  return (
    <div className="articleSearch" data-testid="articleSearch">
      <Tooltip title="Artikel suche nach ID" className="articleSearch__icon" data-testid="articleSearch-searchIcon">
        <span>
          <IconButton
            color="primary"
            onClick={handleSearch}
            className="articleSearch__searchButton"
            data-testid="articleSearch-searchButton"
          >
            <SearchIcon />
          </IconButton>
        </span>
      </Tooltip>
      <input
        id="search-input"
        data-testid="articleSearch-input"
        type="search"
        name="search"
        placeholder="Suche nach ID"
        autoComplete="off"
        className="articleSearch__input"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
        disabled={searching}
      />
      <Tooltip title="Clear Search" className="articleSearch__clearIcon">
        <span>
          <IconButton
            color="primary"
            onClick={clearSearch}
            disabled={searching}
            className="articleSearch__clearButton"
            data-testid="articleSearch-clearSearchButton"
          >
            {searchInput.length > 0 && (
              <div data-testid="articleSearch-clearSearch">
                <Close />
              </div>
            )}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};
