import { memo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BildLogo } from 'src/assets/Logos/Brands/Bild';
import { SportBildLogo } from 'src/assets/Logos/Brands/SportBild';
import { WeltLogo } from 'src/assets/Logos/Brands/Welt';
import { BRAND } from 'src/models/Article';
import { useArticlesContext } from 'src/pages/ArticleLandingPage/store/ArticlesContext';
import { IAuthUser } from 'src/services/Auth/AuthContext';
import { validBrands } from 'src/services/Auth/AuthProvider';
import './VerticalMenu.scss';

type VerticalMenuProps = {
  brand: BRAND;
  switchBrand: (brandName: BRAND | null) => void;
  user: IAuthUser | null;
};
const VerticalMenu = memo(({ brand, switchBrand, user }: VerticalMenuProps) => {
  const navigate = useNavigate();
  const { resetArticlesFilter } = useArticlesContext();

  let brandMenuItems = [
    { brand: BRAND.WELT, logo: WeltLogo },
    { brand: BRAND.BILD, logo: BildLogo },
    { brand: BRAND.SPORTBILD, logo: SportBildLogo },
  ];

  const cpanelSpecificBrands = validBrands.filter((brand) =>
    user?.roles?.some((role) => role.match(`cpanel-${brand}`))
  );

  if (cpanelSpecificBrands.length) {
    brandMenuItems = brandMenuItems.filter((menuItem) => cpanelSpecificBrands.includes(menuItem.brand));
  }

  const brandSwitcherHasHappened = useRef(false);

  const handleBrandChoice = (choosenBrand: BRAND) => {
    resetArticlesFilter();

    switchBrand(choosenBrand);
    brandSwitcherHasHappened.current = true;
    navigate(`/${brand}`);
  };

  useEffect(() => {
    if (brandSwitcherHasHappened.current) {
      navigate(`/${brand}`);
    }
  }, [brand, navigate]);

  return (
    <div className="verticalMenu" data-testid="verticalMenu--wrapper">
      {brandMenuItems.map((menuItem) => {
        const brandIsSelected = brand === menuItem.brand;
        return (
          <div key={menuItem.brand} className={`brandIcon-wrapper ${brandIsSelected && 'selected'}`}>
            <div
              className={`brandIcon brandIcon__${menuItem.brand} ${brandIsSelected && 'selected'}`}
              onClick={() => handleBrandChoice(menuItem.brand)}
              data-testid={`verticalMenu--brandIcon--${menuItem.brand}`}
            >
              <menuItem.logo />
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default VerticalMenu;
