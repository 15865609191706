import Input from '@mui/material/Input';
import TableCell from '@mui/material/TableCell';
import React, { ChangeEvent } from 'react';
import './CustomTableCell.scss';

export type CustomTableCellProps = {
  row: {
    [prop: string]: string;
  };
  isEditMode: boolean;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isEditable: boolean;
  loading: boolean;
};

const CustomTableCell = React.memo<CustomTableCellProps>(({ row, isEditMode, name, onChange, isEditable, loading }) => {
  return (
    <>
      {isEditable ? (
        <TableCell align="left" className="customTableCell">
          {isEditMode ? (
            <Input
              value={row[name]}
              name={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              className="customTableCell__input"
              data-testid={`dictionaryList-TableCellInput--${name}`}
              disabled={loading}
            />
          ) : (
            <div
              className={`customTableCell__cellContent ${loading ? 'lowOpacity' : ''}`}
              data-testid={`dictionaryList-TableCellValue--${name}`}
            >
              {row[name]}
            </div>
          )}
        </TableCell>
      ) : (
        <TableCell align="left" className="customTableCell">
          <div
            className={`customTableCell__cellContent ${loading ? 'lowOpacity' : ''}`}
            data-testid={`dictionaryList-TableCellValue--${name}`}
          >
            {row[name]}
          </div>
        </TableCell>
      )}
    </>
  );
});

export default CustomTableCell;
