import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

function Prompt(props: { when: boolean; id: string; message: string | undefined }) {
  const block = props.when;
  const id = props.id;

  useBlocker(() => {
    if (block) {
      return !window.confirm(props.message);
    }
    return false;
  });

  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string }) => {
      if (block) {
        console.log('beforeunload');
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handleUnload = () => {
      if (block) {
        console.log('unload');
        return
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [block]);

  return <div key={id} />;
}

export default Prompt;
