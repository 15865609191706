import axios, { AxiosInstance } from 'axios';
import CognitoConfig from '../../config/CognitoConfig';

interface ITokenResponse {
  refresh_token?: string;
  id_token?: string;
  access_token?: string;
}
interface IMapedTokenResponse {
  refreshToken: string;
  idToken: string;
  accessToken: string;
}

class CognitoService {
  readonly client: AxiosInstance;
  constructor() {
    this.client = axios.create({
      baseURL: `https://${CognitoConfig.host}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });
  }

  fetchTokens(authorizationCode: string) {
    const data = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: CognitoConfig.client_id,
      code: authorizationCode,
      redirect_uri: `${window.location.protocol}//${window.location.host}/login-success`,
    });

    return this.client
      .post(CognitoConfig.token_endpoint_path, data.toString())
      .then((res) => this.mapTokenResponse(res?.data));
  }

  refreshTokens(refreshToken: string) {
    const data = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: CognitoConfig.client_id,
      refresh_token: refreshToken,
    });

    return this.client
      .post(CognitoConfig.token_endpoint_path, data.toString())
      .then((res) => this.mapTokenResponse(res?.data));
  }

  mapTokenResponse(res: ITokenResponse): IMapedTokenResponse {
    if (!(res && res?.id_token && res?.access_token && res?.refresh_token)) {
      throw new Error('No Id_token | Access_token | Refresh_token found!');
    }
    return {
      refreshToken: res.refresh_token,
      idToken: res.id_token,
      accessToken: res.access_token,
    };
  }
}

export const cognitoService = new CognitoService();
