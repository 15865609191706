import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import React from 'react';
import { TypeLabels } from '../../../../models/Dictionary';
import { CustomTableCellProps } from './CustomTableCell';
import './CustomTableCell.scss';

type CustomTableDropDownCellProps = CustomTableCellProps & {
  onChange: (e: SelectChangeEvent) => void;
};

const CustomTableDropDownCell = React.memo<CustomTableDropDownCellProps>(
  ({ row, isEditMode, name, onChange, loading }) => {
    const dictionaryEntryTypeLiteral = (state: string) => {
      return TypeLabels[state as keyof typeof TypeLabels];
    };
    return (
      <TableCell align="left" className="customCell">
        {isEditMode ? (
          <Select
            variant="standard"
            disabled={loading}
            value={row[name] || 'NONE'}
            name={name}
            displayEmpty
            onChange={(event: SelectChangeEvent) => onChange(event)}
            className="customTableCell__input"
            inputProps={{
              name: 'type',
              id: `type-dropDown-${row.id}`,
            }}
            data-testid={`dictionaryList-TableCellSelectInput--${name}`}
          >
            {Object.entries(TypeLabels).map((entry) => {
              return (
                <MenuItem key={entry[0]} value={entry[0]}>
                  {entry[1]}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <div
            className={`customTableCell__cellContent ${loading ? 'lowOpacity' : ''}`}
            data-testid={`dictionaryList-TableCellSelectValue--${name}`}
          >
            {dictionaryEntryTypeLiteral(row[name]) || ''}
          </div>
        )}
      </TableCell>
    );
  }
);

export default CustomTableDropDownCell;
