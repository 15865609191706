const amplifyAuthConfig = {
  // REQUIRED - Amazon Cognito Region
  region: 'eu-central-1',

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // Required only if it's different from Amazon Cognito Region
  identityPoolRegion: 'eu-central-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-central-1_4ejIPRnEz',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: 'f2ngrvcaivbjro2mqpc65s544', // this default belongs to the staging environment

  authenticationFlowType: 'USER_PASSWORD_AUTH',

  oauth: {
    domain: 'qcc-shared-auth.auth.eu-central-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/logout',
    responseType: 'code',
  },
};

const host = amplifyAuthConfig.oauth.domain;
const token_endpoint_path = '/oauth2/token';
const client_id =
  typeof window !== 'undefined' && window.COGNITO_CLIENT_ID
    ? window.COGNITO_CLIENT_ID
    : amplifyAuthConfig.userPoolWebClientId;

// Attention: window check, because this file will be used by the cypress node js runtime
/*if(typeof window !== 'undefined' && window.COGNITO_CLIENT_ID) {
    client_id = window.COGNITO_CLIENT_ID
}*/

export default {
  ...amplifyAuthConfig,
  host,
  client_id,
  token_endpoint_path,
};
