import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from 'src/services/Auth/AuthContext';
import Header from '../Header/Header';
import VerticalMenu from '../VerticalMenu/VerticalMenu';
import './Layout.scss';

const Layout = memo(() => {
  const { brand, switchBrand, user } = useAuthContext();

  return (
    <div data-testid="layout--wrapper" className="tts-page-wrapper">
      <Header />
      {brand && <VerticalMenu brand={brand} switchBrand={switchBrand} user={user} />}
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
});

export default Layout;
