import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useCallback, useState } from 'react';
import './SearchBar.scss';

type SearchBarProps = {
  isFetchingSearchResults: boolean;
  searchTerm: string;
  onResetSearch: () => void;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setIsSearchDictionaryHookEnabled: Dispatch<SetStateAction<boolean>>;
  countSearchedEntries: number;
};
export default function SearchBar({
  isFetchingSearchResults,
  searchTerm,
  onResetSearch,
  setSearchTerm,
  setIsSearchDictionaryHookEnabled,
  countSearchedEntries,
}: SearchBarProps) {
  const [textFieldValue, setTextFieldValue] = useState(searchTerm);

  const onChangeSearchText = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTextFieldValue(event.target.value);
  }, []);

  const onHandleSearch = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;

      if ((event?.target as HTMLInputElement)?.value.trim().length > 0 && searchTerm !== textFieldValue) {
        setIsSearchDictionaryHookEnabled(true);
        setSearchTerm(textFieldValue);
      }
    },
    [searchTerm, setSearchTerm, textFieldValue, setIsSearchDictionaryHookEnabled]
  );

  const onCancelIconClick = useCallback(() => {
    onResetSearch();
    setTextFieldValue('');
  }, [onResetSearch]);

  return (
    <div className="searchbar--container">
      <TextField
        placeholder="Global Dictionary durchsuchen"
        className="searchbar-input"
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {!isFetchingSearchResults && searchTerm.length === 0 && <SearchIcon className="searchIcon" />}
              {!isFetchingSearchResults && searchTerm.length > 0 && (
                <CancelOutlinedIcon onClick={onCancelIconClick} className="cancelIcon" />
              )}
              {isFetchingSearchResults && <CircularProgress size={20} color="inherit" className="loadingIcon" />}
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        value={textFieldValue}
        onChange={onChangeSearchText}
        onKeyPress={onHandleSearch}
        disabled={isFetchingSearchResults}
        inputProps={{ 'data-testid': 'globalDictionary-search-input' }}
      />
      {searchTerm.length === 0 && countSearchedEntries === 0 && (
        <div className="dictionaryPage--search-info" data-testid="globalDictionary-search-info">
          Finde alle Einträge im Global Dictionary. Du kannst sie ganz einfach <br />
          editieren, entfernen oder einen Neuen Eintrag hinzufügen.
        </div>
      )}

      {searchTerm.length !== 0 && !isFetchingSearchResults && countSearchedEntries === 0 && (
        <div className="dictionaryPage--search-info" data-testid="globalDictionary-noEntry-message">
          Keine Einträge gefunden!
        </div>
      )}
    </div>
  );
}
