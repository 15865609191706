import { Switch } from '@mui/material';
import React from 'react';
import '../../cpanel.scss';

interface FeatureFlagProps {
  name: string;
  description: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dataTestId: string;
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({ name, description, checked, onChange, dataTestId }) => {
  return (
    <>
      <section className="cpanel-list-table cpanel-list-table--2Column" data-testid={dataTestId}>
        <div data-testid="feature-flag-details" className="cpanelGrid__cell cpanelGrid__cell--flexColumn">
          <b>{name}</b>
          {description}
        </div>
        <div className="cpanelList-item--checkbox cpanelGrid__cell">
          <Switch checked={checked} onChange={onChange} />
        </div>
      </section>
    </>
  );
};

export default FeatureFlag;
