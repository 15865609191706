import { createContext, FunctionComponent, PropsWithoutRef, useContext } from 'react';
import { IArticle } from '../../../models/Article';
import ArticlesProvider, {
  ArticlesStateWithActions,
  initialArticlesState,
  SetArticlesFiltersCmd,
} from './ArticlesProvider';

export const articlesContextDefaultValue = {
  ...initialArticlesState,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  toggleNewArticleForm: (_showForm: boolean) => undefined,
  toggleAlexaUpdateModal: (_showModal: boolean) => undefined,
  setLoadingMore: (_loadMoreArticles: boolean) => undefined,
  setCheckedArticlesIds: (_articleIds: string[]) => undefined,
  setSelectedAction: (_action: string) => undefined,
  setArticles: (_articles: IArticle[]) => undefined,
  toggleConfirmActionDialog: (_showDialog: boolean) => undefined,
  setArticlesFilter: (_filtersCmd: SetArticlesFiltersCmd) => undefined,
  resetArticlesFilter: () => undefined,
  handleArticleSearch: (_searchResult: IArticle) => undefined,
  /* eslint-enable @typescript-eslint/no-unused-vars */
};
const ArticlesContext = createContext<ArticlesStateWithActions>(articlesContextDefaultValue);

export const useArticlesContext = () => useContext(ArticlesContext);

export const WithArticlesContext = (WrappedComponent: FunctionComponent) => (props: PropsWithoutRef<any>) =>
  (
    <ArticlesProvider>
      <WrappedComponent {...props} />
    </ArticlesProvider>
  );

export default ArticlesContext;
