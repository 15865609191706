import {
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dispatch } from 'react';
import { TypeLabels } from '../../../../models/Dictionary';
import './CreateEntryModal.scss';
import { CREATE_ENTRY_ACTIONS, isEmptyEntry, MAX_NUMBER_ENTRIES, NewEntry, Payload } from './createEntryModalReducer';

export default function CreateEntryModalContent({
  newEntries,
  onChangeEntry,
  onClearEntry,
  dispatchCreateEntry,
}: {
  newEntries: NewEntry[];
  onChangeEntry: (index: number, name: string, changeValue: string | unknown) => void;
  onClearEntry: (index: number) => void;
  dispatchCreateEntry: Dispatch<{ type: CREATE_ENTRY_ACTIONS; payload?: Payload }>;
}) {
  return (
    <DialogContent className="createEntryModalContent--container">
      <Table>
        <TableBody>
          {newEntries?.map((_, i) => (
            <TableRow key={i}>
              <TableCell>
                <TextField
                  variant="standard"
                  className={newEntries[i]?.error && newEntries[i]?.error !== '' ? 'error-input' : ''}
                  error={newEntries[i]?.error ? newEntries[i]?.error !== '' : false}
                  InputProps={{ disableUnderline: true }}
                  placeholder={'Original'}
                  value={newEntries[i]?.original}
                  onChange={(e) => onChangeEntry(i, 'original', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="standard"
                  className={newEntries[i]?.error && newEntries[i]?.error !== '' ? 'error-input' : ''}
                  error={newEntries[i]?.error ? newEntries[i]?.error !== '' : false}
                  InputProps={{ disableUnderline: true }}
                  placeholder={'Adaption'}
                  value={newEntries[i]?.adaption}
                  onChange={(e) => onChangeEntry(i, 'adaption', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <FormControl size="small">
                  <Select
                    variant="standard"
                    className={newEntries[i]?.error && newEntries[i]?.error !== '' ? 'error-input' : ''}
                    error={newEntries[i]?.error ? newEntries[i]?.error !== '' : false}
                    disableUnderline
                    onChange={(e) => onChangeEntry(i, 'type', e.target.value)}
                    value={newEntries[i]?.type}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? TypeLabels[value as keyof typeof TypeLabels] : 'Typ')}
                  >
                    {Object.entries(TypeLabels).map((entry) => {
                      return (
                        <MenuItem key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => onClearEntry(i)}
                  disabled={isEmptyEntry(newEntries[i])}
                  className="clearIcon"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {newEntries.length !== MAX_NUMBER_ENTRIES && (
            <TableRow>
              <TableCell>
                <div
                  className="add-icon"
                  onClick={() => dispatchCreateEntry({ type: CREATE_ENTRY_ACTIONS.ADD_NEW_ENTRY })}
                >
                  <AddIcon fontSize={'inherit'} /> Anderes Wort Hinzufügen
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </DialogContent>
  );
}
