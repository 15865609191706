import { ArticleStatus } from '../../../models/Article';
import { ColumnName } from '../ArticlesFilterHeader/ArticlesFilterHeader';
import { ArticlesState, SetArticlesFiltersCmd } from './ArticlesProvider';

export enum ArticlesAction {
  SET_ARTICLES = 'SET_ARTICLES',
  TOGGLE_ARTICLE_FORM = 'TOGGLE_ARTICLE_FORM',
  TOGGLE_ALEXA_MODAL = 'TOGGLE_ALEXA_MODAL',
  SET_LOAD_MORE_ARTICLES = 'SET_LOAD_MORE_ARTICLES',
  SET_DISABLE_AUTO_POLLING = 'SET_DISABLE_AUTO_POLLING',
  SET_CHECKED_ARTICLES = 'SET_CHECKED_ARTICLES',
  SET_SELECTED_ACTION = 'SET_SELECTED_ACTION',
  TOGGLE_CONFIRM_DIALOG = 'TOGGLE_CONFIRM_DIALOG',
  SET_ARTICLES_FILTERS = 'SET_ARTICLES_FILTERS',
  RESET_ARTICLES_FILTERS = 'RESET_ARTICLES_FILTERS',
  SEARCH_ARTICLES = 'SEARCH_ARTICLES',
}

export interface IArticlesAction {
  type: ArticlesAction;
  payload?: any;
}

const articlesReducer = (state: ArticlesState, action: IArticlesAction): ArticlesState => {
  const { type, payload } = action;

  switch (type) {
    case ArticlesAction.SET_ARTICLES: {
      return {
        ...state,
        articles: payload,
      };
    }
    case ArticlesAction.TOGGLE_ARTICLE_FORM: {
      return {
        ...state,
        isFormOpen: payload,
      };
    }
    case ArticlesAction.TOGGLE_ALEXA_MODAL: {
      return {
        ...state,
        isModalOpen: payload,
      };
    }
    case ArticlesAction.SET_LOAD_MORE_ARTICLES: {
      return {
        ...state,
        loadingMore: payload,
      };
    }
    case ArticlesAction.SET_CHECKED_ARTICLES: {
      return {
        ...state,
        checkedArticleIds: payload,
      };
    }
    case ArticlesAction.SET_SELECTED_ACTION: {
      return {
        ...state,
        selectedAction: payload,
      };
    }
    case ArticlesAction.TOGGLE_CONFIRM_DIALOG: {
      return {
        ...state,
        showConfirmActionDialog: payload,
      };
    }
    case ArticlesAction.SET_ARTICLES_FILTERS: {
      const { filterType, value } = payload as SetArticlesFiltersCmd;
      if (filterType === ColumnName.RESSORTS) {
        return {
          ...state,
          selectedResort: value,
        };
      } else if (filterType === ColumnName.STATUS) {
        return {
          ...state,
          selectedStatus: value as ArticleStatus | null,
        };
      }
      return state;
    }
    case ArticlesAction.RESET_ARTICLES_FILTERS: {
      return {
        ...state,
        selectedResort: null,
        selectedStatus: null,
      };
    }
    case ArticlesAction.SEARCH_ARTICLES: {
      return {
        ...state,
        articles: [payload],
      };
    }
    case ArticlesAction.SET_DISABLE_AUTO_POLLING: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default articlesReducer;
