import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmationDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  text: string;
  actionLabel: string;
};

export const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  text,
  actionLabel,
}: ConfirmationDialogProps) => {
  return (
    <Dialog data-testid="confirmDialog" open={open} onClose={handleClose}>
      <DialogTitle data-testid="confirmDialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary" data-testid="cancelConfirmation">
          Abbrechen
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary" data-testid="confirm-dialog">
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
