import { BRAND } from 'src/models/Article';

export const scrollTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const composeArticleIdTextInput = (textInput: string, brand: BRAND | null) => {
  if (!brand || textInput.includes('___')) {
    return textInput;
  }
  return `${brand}___${textInput}`;
};

export const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const validateDayRange = (dayRange: string): boolean => {
  // matches START-END with three uppercase letters each with matching the hyphen separator
  const dayPattern = /^([A-Z]{3})-([A-Z]{3})$/;

  if (!dayPattern.test(dayRange)) {
    return false;
  }

  const [startDay, endDay] = dayRange.split('-');
  const startIndex = daysOfWeek.indexOf(startDay);
  const endIndex = daysOfWeek.indexOf(endDay);

  // Ensure start day comes before end day in the week / start and end day should not be same
  if (startIndex >= endIndex) {
    return false;
  }

  return true;
};
