import { Button, Switch, TextField } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { validateDayRange } from '../../../../../src/helpers/Helpers';
import { NotificationService } from '../../../../components/Notifications/Notifications';
import '../../cpanel.scss';

interface CategoryProps {
  name: string;
  checked: boolean;
  categoryDays: string;
  categoryTimes: string;
  dataTestId: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onUpdate: (dayRange: string, timeRange: string) => void;
}

const Category: FC<CategoryProps> = ({
  name,
  checked,
  categoryDays,
  categoryTimes,
  dataTestId,
  onChange,
  onUpdate,
}) => {
  const initializeStartTime = (categoryTimes: string) => {
    if (categoryTimes != '') {
      const timeRange = categoryTimes.split('-');
      setNewStartTime(timeRange[0]);
    } else {
      setNewStartTime('07:00');
    }
  };

  const initializeEndTime = (categoryTimes: string) => {
    if (categoryTimes != '') {
      const timeRange = categoryTimes.split('-');
      setNewEndTime(timeRange[1]);
    } else {
      setNewEndTime('18:00');
    }
  };

  const [newDayRange, setNewDayRange] = useState(categoryDays);

  const [newStartTime, setNewStartTime] = useState<string>('');
  const [newEndTime, setNewEndTime] = useState<string>('');

  const [isDayRangeChanged, setIsDayRangeChanged] = useState(false);
  const [isTimeRangeChanged, setIsTimeRangeChanged] = useState(false);

  const handleUpdateClick = () => {
    if (parseInt(newStartTime) >= parseInt(newEndTime)) {
      NotificationService.error(
        `Validation failed for time at resort: ${name}. Please provide a valid time range. Start time should be before the end time.`
      );
      return;
    }

    if (!validateDayRange(newDayRange)) {
      NotificationService.error(
        `Validation failed for days at resort: ${name}. Please provided a valid day range following the required format`
      );
      return;
    }

    const newTimeRange = `${newStartTime}-${newEndTime}`;
    onUpdate(newDayRange, newTimeRange);
    setIsDayRangeChanged(false);
    setIsTimeRangeChanged(false);
  };

  const handleDayRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewDayRange(e.target.value);
    setIsDayRangeChanged(e.target.value !== categoryDays);
  };

  const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewStartTime(e.target.value);
    setIsTimeRangeChanged(e.target.value !== categoryTimes);
  };

  const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewEndTime(e.target.value);
    setIsTimeRangeChanged(e.target.value !== categoryTimes);
  };

  useEffect(() => {
    if (checked) {
      setNewDayRange(categoryDays);
      initializeStartTime(categoryTimes);
      initializeEndTime(categoryTimes);
    } else {
      setNewDayRange('');
      initializeStartTime(categoryTimes);
      initializeEndTime(categoryTimes);
    }
  }, [checked, categoryDays, categoryTimes]);

  return (
    <section className="cpanel-list-table cpanel-list-table--2CategoryColumn " data-testid={dataTestId}>
      <div className="cpanelGrid__cell">
        <div data-testid="category-name">{name}</div>
        <div className="cpanelList-item--checkbox ">
          <Switch checked={checked} onChange={onChange} />
        </div>
      </div>
      <div className="cpanelGrid__cell">
        <div className="cpanelGrid cpanelGrid__cell--inlineFlex ">
          <TextField
            className="customWidthTextField--100"
            data-testid="category-days"
            type="text"
            disabled={!checked}
            placeholder="MON-FRI"
            variant="standard"
            value={newDayRange}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 7 }}
            onChange={handleDayRangeChange}
          />

          <input
            data-testid="category-start-time"
            aria-label="startTime"
            type="time"
            onChange={handleStartTimeChange}
            disabled={!checked}
            value={newStartTime}
            className={!checked ? 'inputDisabled' : ''}
          />
          <input
            data-testid="category-end-time"
            aria-label="endTime"
            type="time"
            onChange={handleEndTimeChange}
            disabled={!checked}
            value={newEndTime}
            className={!checked ? 'inputDisabled' : ''}
          />
        </div>

        <div className="category-button">
          <Button
            variant="contained"
            disabled={!checked || (!isDayRangeChanged && !isTimeRangeChanged)}
            onClick={handleUpdateClick}
          >
            Update
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Category;
