export const SportBildLogo = () => (
  <svg width="72" height="90" viewBox="0 0 173 206" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M369.4,412.47V364.32l5.36-1.46V348.38l-5.36,1.46v-16L356,337.63v15.83l-2.57.7v-1.67a8.17,8.17,0,0,0-5.38,5.69c-1,3.08-3.39,11.14-3.49,11.47v-13l-13.46,3.67v80l13.46-3.68V387.83c.1-5.08,3.57-8.08,6-8.71s2.86-.69,2.86-.69v-9.79l2.57-.7v40.27h0v14.07c0,10.58,5.92,10.21,11.93,8.58l6.91-1.88V413.9s-5.16,2.21-5.36-1.43"
      transform="translate(-216.25 -322.24)"
    />
    <path
      d="M315.26,426c0,4-1.7,5.47-2.88,5.84s-2.87-.65-2.87-3h0V385h0c0-5,.61-6.56,2.76-7.13a2.21,2.21,0,0,1,2.55,1.76,12.57,12.57,0,0,1,.5,3V409h0Zm13.49-43.33h0c0-19.63-10.49-20.57-15.68-19.75-6.36,1-17,7.29-17,25.53h0v38.27h0c0,.54,0,1.07,0,1.59v.61h0c.49,14.26,6.95,20.78,16.8,18,9.36-2.67,15.87-7.66,15.87-37.62V382.68Z"
      transform="translate(-216.25 -322.24)"
    />
    <path
      d="M280.08,435.71c0,3-1,4.27-2.63,4.6-1.37.28-3.15-1-3.15-6V397.77c.16-7.94,3.11-8.35,3.29-8.42s2.49-.63,2.49,2.93v3.54l0,0v37.53c0,.07,0,.19,0,2.38m13.47-51.89h0c0-6.31-2.29-12.65-7.1-12.65a10.52,10.52,0,0,0-9.51,6.21c-1.32,2.85-2.48,5.83-2.62,6.2v-7.8l-13.45,3.54V472.9l13.45-3.61v-20.8c.08.15.42.73,1.43,2.17,1.22,1.72,3.39,5,8.39,3.59s9.37-6.76,9.42-17.89V383.82Z"
      transform="translate(-216.25 -322.24)"
    />
    <path
      d="M250.75,411.33l0,0-9.14-9.3-.12-.13c-5.22-5.44-6.75-8.1-6.75-12.29,0-4.38,1.8-8.27,5-9.1s4.31,2.64,4.31,9.59v6l13.49-3.68v-7.58h0c-.06-13-3.08-22.53-12.37-22.53s-23.77,9-23.77,29.62c0,10.22.25,16.12,6.3,23.95L237,425.3l.14.12c1.63,1.54,7.34,6.77,7.34,13.45,0,7.43-3.13,9.38-4.8,9.94s-5.21.55-5.21-7.3v-4h0v-5.4l-13.88,3.76,0,2.21v2.5c0,9.1.83,30.86,18.14,26.2,17.6-4.73,19.74-22.8,19.74-33.09,0-9.9-.79-14.78-7.72-22.32"
      transform="translate(-216.25 -322.24)"
    />
    <path
      d="M290.9,483.58h6c2.37,0,3.87-1,3.87-4.47,0-2.73-1.55-3.72-3.87-3.72h-6Zm0,20.17h6.65c2.37,0,4.43-1,4.43-5.15,0-3.19-1.28-4.93-4-4.93H290.9ZM279.56,462.5h20.16c9.69,0,11.75,8,11.75,13.57s-1.8,8.42-4.53,10.62c3.29,1.67,6.39,5.54,6.39,13.5,0,10.84-6.39,16.45-12.89,16.45H279.56Z"
      transform="translate(-216.25 -322.24)"
    />
    <path d="M325.44,473H315.23V462.5h10.21Zm-10.21,4.25h10.21v39.35H315.23Z" transform="translate(-216.25 -322.24)" />
    <path d="M112.64 140.26h10.21v54.14h-10.21z" />
    <path
      d="M355.84,487.22c-3.66,0-4.48,5.08-4.48,9.71s.82,9.78,4.48,9.78,4.49-5.08,4.49-9.78-.82-9.71-4.49-9.71m14.34,29.42h-9.85v-4.32h-.1c-1.29,2.5-2.84,5.31-7.84,5.31-7.58,0-11.24-11-11.24-20.85,0-9.25,2.42-20.48,11.39-20.48,3,0,5.78,2,7.33,5.31h.1V462.5h10.21Z"
      transform="translate(-216.25 -322.24)"
    />
  </svg>
);
