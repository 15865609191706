import { ArticleStatus, ArticleStatusToView } from '../../models/Article';
import { CSS_CLASS_NAMES } from './ArticleStatusBadge';

export const getBadgeOptions = (
  status: ArticleStatus
): { label: typeof ArticleStatusToView[keyof typeof ArticleStatusToView]; cssClass: string } => {
  if (status === ArticleStatus.AUTO_PUBLISHED) {
    return {
      label: ArticleStatusToView[status],
      cssClass: CSS_CLASS_NAMES.SYNTHESIS_SUCCESS,
    };
  }
  if (status === ArticleStatus.NOT_RUN || status === ArticleStatus.RUNNING || status === ArticleStatus.PUBLISHING) {
    return {
      label: ArticleStatusToView[status],
      cssClass: CSS_CLASS_NAMES.RUNNING,
    };
  }
  if (status === ArticleStatus.SYNTHESIZED) {
    return {
      label: ArticleStatusToView[status],
      cssClass: CSS_CLASS_NAMES.SYNTHESIS_SUCCESS,
    };
  }
  if (status === ArticleStatus.CHECKED || status === ArticleStatus.PRE_CHECKED) {
    return {
      label: ArticleStatusToView[status],
      cssClass: CSS_CLASS_NAMES.PUBLICATION_SUCCESS,
    };
  }
  if (status === ArticleStatus.DEPUBLISHED) {
    return {
      label: ArticleStatusToView[status],
      cssClass: CSS_CLASS_NAMES.DEPUBLISHED,
    };
  }
  if (status === ArticleStatus.FAILED) {
    return {
      label: ArticleStatusToView[status],
      cssClass: CSS_CLASS_NAMES.FAILURE,
    };
  }

  return {
    label: ArticleStatusToView.UNKNOWN,
    cssClass: '',
  };
};
