import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useMemo } from 'react';
import { useBrowserNotification } from '../../hooks';
import { NOTIFICATION_PERMISSION } from '../../hooks/useBrowserNotification/useBrowserNotification';

const BrowserNotificationToggler = () => {
  const { notificationStatus, enableNotification } = useBrowserNotification();

  const handleEnableNotfication = (): boolean | void => {
    if (disabled) {
      return false;
    }
    enableNotification();
  };

  const notificationTooltip = useMemo(() => {
    let notificationText = '';
    switch (notificationStatus) {
      case NOTIFICATION_PERMISSION.DEFAULT:
        notificationText = 'Enable Desktop Notification';
        break;
      case NOTIFICATION_PERMISSION.GRANTED:
        notificationText = 'Desktop Notification is enabled';
        break;
      case NOTIFICATION_PERMISSION.DENIED:
        notificationText = 'Desktop Notification is disabled';
        break;
      default:
        break;
    }
    return notificationText;
  }, [notificationStatus]);

  const disabled =
    notificationStatus === NOTIFICATION_PERMISSION.GRANTED || notificationStatus === NOTIFICATION_PERMISSION.DENIED;
  const color = notificationStatus === NOTIFICATION_PERMISSION.DEFAULT ? 'primary' : 'default';

  const renderButton = () => {
    return (
      <Tooltip title={notificationTooltip}>
        <span>
          <IconButton
            onClick={handleEnableNotfication}
            color={color}
            disabled={disabled}
            data-testid="notification-toggle-button"
          >
            {notificationStatus === NOTIFICATION_PERMISSION.GRANTED && (
              <NotificationsActiveIcon data-testid="notification-toggle-icon--granted" />
            )}
            {notificationStatus === NOTIFICATION_PERMISSION.DEFAULT && (
              <NotificationsIcon data-testid="notification-toggle-icon--default" />
            )}
            {notificationStatus === NOTIFICATION_PERMISSION.DENIED && (
              <NotificationsOffIcon data-testid="notification-toggle-icon--denied" />
            )}
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return notificationStatus ? renderButton() : null;
};

export default BrowserNotificationToggler;
