import cognito from '../../config/CognitoConfig';

const Login = () => {
  const redirect_uri = encodeURIComponent(`${window.location.protocol}//${window.location.host}/login-success`);
  const state = `from=${window.location.href}`;
  const cognitoLoginViewUrl = `https://${cognito.host}/login?response_type=code&client_id=${
    cognito.client_id
  }&scope=openid&redirect_uri=${redirect_uri}&state=${encodeURIComponent(state)}`;
  window.location.replace(cognitoLoginViewUrl);

  return null;
};

export default Login;
