import CancelIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/PlaylistAddCheck';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { motion } from 'framer-motion';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { NotificationService } from '../../../../components/Notifications/Notifications';
import { DictionaryEntryType } from '../../../../models/Dictionary';
import DictionaryService, { updateEntryPayload } from '../../../../services/DictionaryService';
import CustomTableDropDownCell from '../CustomTableCells/CustomDropDownCell';
import CustomTableCell from '../CustomTableCells/CustomTableCell';
import './EntryTableRow.scss';

type EntryTableRowProps = {
  index: number;
  entry: any;
  refetchSearchResults: () => void;
};

type RowStateProps = {
  id?: string;
  name?: string;
  original: string;
  type: DictionaryEntryType;
  adaption: string;
};

const EntryTableRow = React.memo(({ index, entry, refetchSearchResults }: EntryTableRowProps) => {
  const createRowState = (name: string, original: string, type: DictionaryEntryType, adaption: string) => ({
    id: name?.replace(' ', '_'),
    name,
    original,
    type,
    adaption,
  });

  const [row, setRow] = React.useState<RowStateProps>({} as RowStateProps);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showTools, setShowTools] = useState<boolean>(false);
  const [previous, setPrevious] = React.useState<RowStateProps>({} as RowStateProps);

  useEffect(() => {
    const entryState = createRowState(entry.original, entry.original, entry.type, entry.adaption);
    if (entryState) setRow(entryState);
  }, [entry]);

  const onChange = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    if (!Object.keys(previous).length) {
      setPrevious(row);
    }

    const value = e.target.value;
    const name = e.target.name;

    setRow({ ...row, [name]: value });
  };

  const handleCancel = useCallback(() => {
    if (Object.keys(previous).length) {
      setRow({
        ...row,
        id: previous.id,
        name: previous.name,
        original: previous.original,
        type: previous.type,
        adaption: previous.adaption,
      });
    }

    setIsEditMode(false);
    setPrevious({} as RowStateProps);
  }, [previous]);

  const handleUpdate = () => {
    const command: updateEntryPayload = {
      adaption: row.adaption,
      type: row.type,
    };

    setLoading(true);
    setIsEditMode(false);

    DictionaryService.updateEntry(row.original, command)
      .then((res) => {
        setRow(res);
        refetchSearchResults();
        NotificationService.success(`Der Eintrag: ${res.original}, wurde erfolgreich im Wörterbuch aktualisiert.`);
      })
      .catch((error) => {
        NotificationService.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    setIsEditMode(false);

    DictionaryService.deleteEntry(row.original)
      .then(() => {
        refetchSearchResults();
        NotificationService.success(`Der Eintrag: ${row.original}, wurde erfolgreich im Wörterbuch gelöscht.`);
      })
      .catch((error) => {
        console.error(error);
        NotificationService.error(`Beim Löschen des Wörterbucheintrags: ${row.original}, ist etwas schief gelaufen.`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <TableRow
      key={index}
      className={`${isEditMode ? 'rowEditMode' : ''}`}
      data-testid="dictionary--editable--row"
      onClick={!loading && !isEditMode ? () => setIsEditMode(true) : () => null}
      onMouseEnter={() => setShowTools(true)}
      onMouseLeave={() => setShowTools(false)}
    >
      <CustomTableCell
        {...{
          row,
          name: 'original',
          onChange,
          isEditable: false,
          loading: loading,
          isEditMode: isEditMode,
        }}
      />
      <CustomTableDropDownCell
        {...{
          row,
          name: 'type',
          onChange,
          isEditable: true,
          loading: loading,
          isEditMode: isEditMode,
        }}
      />
      <CustomTableCell
        {...{
          row,
          name: 'adaption',
          onChange,
          isEditable: true,
          loading: loading,
          isEditMode: isEditMode,
        }}
      />
      <TableCell className="selectTableCell">
        {!loading && (
          <>
            {isEditMode && (
              <>
                <IconButton
                  aria-label="cancel"
                  onClick={() => {
                    setShowTools(false);
                    handleCancel();
                  }}
                  data-testid="dictionaryList-cancelButton"
                >
                  <CancelIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete()}
                  data-testid="dictionaryList-deleteButton"
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton aria-label="add" onClick={() => handleUpdate()} data-testid="dictionaryList-editButton">
                  <AddIcon />
                </IconButton>
              </>
            )}
            {showTools && !isEditMode && (
              <motion.div
                key={`tools-rowid-${index}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                exit={{ opacity: 0 }}
              >
                <IconButton aria-label="edit" data-testid="dictionaryList-editButton">
                  <EditIcon />
                </IconButton>
              </motion.div>
            )}
          </>
        )}

        {loading && (
          <CircularProgress
            data-testid="dictionaryList-loadingSpinner"
            style={{
              height: '15px',
              width: '15px',
              marginRight: '15px',
              color: 'darkgray',
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

export default EntryTableRow;
