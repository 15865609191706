import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { DictionaryEntry } from '../../models/Dictionary';
import DictionaryService from '../../services/DictionaryService';

type UseSearchDictionaryEntriesRequest = {
  searchTerm: string;
  isSearchDictionaryHookEnabled: boolean;
  setSearchedEntries: Dispatch<SetStateAction<DictionaryEntry[]>>;
  setIsSearchDictionaryHookEnabled: Dispatch<SetStateAction<boolean>>;
};

export function useSearchDictionaryEntries({
  searchTerm,
  isSearchDictionaryHookEnabled,
}: UseSearchDictionaryEntriesRequest) {
  const formatSearchTerm = searchTerm.trim();
  return useQuery({
    queryKey: ['searchTerm'],
    queryFn: () => DictionaryService.searchList(formatSearchTerm),
    enabled: isSearchDictionaryHookEnabled,
  });
}
