import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import { ArticleStatus } from '../../../models/Article';

export type ArticleFooterProps = {
  handleUpdate(autoPublish?: boolean): void;
  pollingForStatusUpdate: boolean;
  status: ArticleStatus;
  readonly: boolean;
  autoPublishChecked: () => Promise<void>;
  publicationPossible: boolean;
  handlePublish: () => void;
};
const ArticleFooter = ({
                         status,
                         autoPublishChecked,
                         pollingForStatusUpdate,
                         handleUpdate,
                         publicationPossible,
                         readonly,
                         handlePublish,
                       }: ArticleFooterProps) => {
  return (
    <section className='articleJobDetails--footer' data-testid='articleDetails--footer'>
      <Button
        variant='outlined'
        color='primary'
        onClick={() => handleUpdate()}
        data-testid='articleDetails--submitButton'
        disabled={pollingForStatusUpdate || status === ArticleStatus.PREMIUM}
        style={{
          background: '#FFF',
        }}
      >
        Speichern
      </Button>

      {(status === ArticleStatus.AUTO_PUBLISHED || status === ArticleStatus.PRE_CHECKED) && (
        <Button
          variant='contained'
          color='primary'
          onClick={() => autoPublishChecked()}
          data-testid='autoPublish-check-button'
          disabled={!readonly || pollingForStatusUpdate}
          style={{ marginLeft: 20 }}
        >
          <Check />
        </Button>
      )}

      <Button
        variant='outlined'
        color='primary'
        disabled={!publicationPossible}
        data-testid='publish-button'
        onClick={handlePublish}
        style={{ marginLeft: 'auto', background: '#FFF' }}
      >
        Veröffentlichen
      </Button>

      <Button
        variant='contained'
        color='primary'
        disabled={!publicationPossible}
        data-testid='synth-publish-button'
        onClick={() => handleUpdate(true)}
        style={{ marginLeft: '20px' }}
      >
        Speichern & Veröffentlichen
      </Button>
    </section>
  );
};

export default ArticleFooter;
