import { useEffect, useRef } from 'react';

export function useUpdateEffect(callback: () => void, dependencies: string[] | null) {
  const firstRenderRef = useRef(true);

  if (!dependencies) {
    return;
  }

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
  }, dependencies);
}
