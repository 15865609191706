import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { deriveApiUrl } from '../DeriveApiUrl';
import { throwHttpError } from './HttpErrorHandlers';

export class HttpClient {
  readonly client: AxiosInstance;
  private unauthorizedResponseInterceptorId: number | undefined;

  constructor(axiosClient?: AxiosInstance) {
    if (axiosClient) {
      this.client = axiosClient;
    } else {
      this.client = axios.create({
        baseURL: deriveApiUrl(window.location.href),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
  }

  removeExistingUnauthorizedInterceptor() {
    if (this.unauthorizedResponseInterceptorId) {
      axios.interceptors.response.eject(this.unauthorizedResponseInterceptorId);
    }
  }

  initUnauthorizedInterceptor(refreshSession: () => Promise<string | void>) {
    this.removeExistingUnauthorizedInterceptor();

    this.unauthorizedResponseInterceptorId = this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.config &&
          !error.response.config.__isRetryRequest
        ) {
          console.info('Got 401 from api. Start refreshing session.');

          return refreshSession().then((token) => {
            const originalRequest = { ...error.response.config, __isRetryRequest: true };
            const { headerName, headerValue } = toAuthorizationHeader(token ?? '');
            originalRequest.headers[headerName] = headerValue;
            console.log('Refreshing session successful, resending request...');
            return this.client(originalRequest);
          });
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  setAuthorizationHeader(token: string) {
    const { headerName, headerValue } = toAuthorizationHeader(token);

    this.client.defaults.headers.common[headerName] = headerValue;
  }

  unsetAuthorizationHeader() {
    const { headerName } = toAuthorizationHeader();

    delete this.client.defaults.headers.common[headerName];
  }

  private request(cmd: AxiosRequestConfig): Promise<AxiosResponse['data']> {
    return this.client
      .request(cmd)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch(throwHttpError);
  }

  get<T>(
    url: string,
    params?: AxiosRequestConfig['params'],
    cancelToken?: AxiosRequestConfig['cancelToken']
  ): Promise<T> {
    return this.request({
      url,
      method: 'GET',
      params,
      cancelToken,
    });
  }

  put<T>(url: string, data: AxiosRequestConfig['data']): Promise<T> {
    return this.request({
      url,
      method: 'PUT',
      data,
    });
  }

  patch<T>(url: string, data: AxiosRequestConfig['data']): Promise<T> {
    return this.request({
      url,
      method: 'PATCH',
      data,
    });
  }

  post<T>(url: string, data: AxiosRequestConfig['data']): Promise<T> {
    return this.request({
      url,
      method: 'POST',
      data,
    });
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request({
      url,
      method: 'DELETE',
      ...config,
    });
  }
}

const toAuthorizationHeader = (token = '') => {
  return {
    headerName: 'Authorization',
    headerValue: token,
  };
};

export const httpClient = new HttpClient();
