import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className={'notfound--wrapper'} data-testid="notFound-container">
      <h1>Diese Seite konnte nicht gefunden werden.</h1>
      <p>
        Gründe dafür könnten sein, dass Sie eine falsche oder veraltete URL aufgerufen haben - bitte überprüfen Sie
        diese noch einmal. Oder aber wir haben die betreffende Seite archiviert, verschoben, gelöscht oder umbenannt.
      </p>
      <p>Vielleicht finden Sie den von Ihnen gewünschten Inhalt über unsere Startseite.</p>

      <Link to="/">Startseite</Link>
    </div>
  );
};

export default NotFound;
