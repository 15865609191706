import jwt_decode from 'jwt-decode';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICognitoUser, validBrands } from 'src/services/Auth/AuthProvider';
import { NotificationService } from '../../components/Notifications/Notifications';
import { useAuthContext } from '../../services/Auth/AuthContext';

const useCognitoResponse = () => {
  let redirectTo = '/';

  const params = new URLSearchParams(window.location.search.slice(1));

  if (params.has('state')) {
    const stateParams = new URLSearchParams(params.get('state') as string);
    if (stateParams.has('from')) {
      const fromUrl = new URL(stateParams.get('from') as string);
      redirectTo = `${fromUrl.pathname}${fromUrl.search}${fromUrl.hash}`;
    }
  }
  const authorizationCode = params.get('code');

  return { redirectTo, authorizationCode };
};

const LoginSuccess = () => {
  const { startSession, loading, userAuthenticated: authenticated } = useAuthContext();

  const { redirectTo, authorizationCode } = useCognitoResponse();

  const initialized = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (authorizationCode && !loading && !authenticated) {
      if (!initialized.current) {
        initialized.current = true;

        startSession(authorizationCode)
          .then((idToken) => {
            const userFromToken: ICognitoUser = jwt_decode(idToken as string);

            const cpanelBrandFromCognitoGroup = validBrands.find((brand) =>
              userFromToken['cognito:groups'].some((role) => role.match(`cpanel-${brand}`))
            );

            if (cpanelBrandFromCognitoGroup) {
              navigate(`/${cpanelBrandFromCognitoGroup}/cpanel`);
            } else {
              navigate(redirectTo);
            }
          })
          .catch(() => {
            NotificationService.error('Authentifizierung fehlgeschlagen.');
          });
      }
    }
  }, [authorizationCode, loading, authenticated]);

  return null;
};

export default LoginSuccess;
