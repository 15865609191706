import Alert from '@mui/material/Alert';
import { EventEmitter } from 'fbemitter';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import './Notifications.scss';

interface INotification {
  id: number;
  severity: SEVERITY;
  message: ReactNode | string;
  visible: boolean;
}

enum EVENT {
  NOTIFY = 'NOTIFY',
  CLOSE_ALL_NOTIFICATIONS = 'CLOSE_ALL_NOTIFICATIONS',
}

enum SEVERITY {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const NOTIFY_CLOSE_TIMEOUT = 2500;
const ERROR_CLOSE_TIMEOUT = 10000;
const emitter = new EventEmitter();

export const NotificationService = {
  success: (message: string) => emitter.emit(EVENT.NOTIFY, { severity: SEVERITY.SUCCESS, message: message }),
  info: (message: string) => emitter.emit(EVENT.NOTIFY, { severity: SEVERITY.INFO, message: message }),
  warning: (message: string) => emitter.emit(EVENT.NOTIFY, { severity: SEVERITY.WARNING, message: message }),
  error: (message: string) => emitter.emit(EVENT.NOTIFY, { severity: SEVERITY.ERROR, message: message }),
  closeAll: () => emitter.emit(EVENT.CLOSE_ALL_NOTIFICATIONS),
};

export const NotificationsContainer = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const removeNotification = (notificationId: number) => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.id !== notificationId)
    );
  };

  const closeNotification = (notificationToClose: INotification) => {
    setNotifications((notifications) => {
      const notification = notifications.find((notification) => notification.id === notificationToClose.id);
      notification ? (notification.visible = false) : null;
      return [...notifications];
    });

    setTimeout(() => removeNotification(notificationToClose.id), 500);
  };

  const addNotification = (severity: SEVERITY, message: string) => {
    const notification = { id: new Date().getTime(), severity, message, visible: true };
    setNotifications((currentNotifications) => [...currentNotifications, notification]);

    const timeout = severity === 'error' ? ERROR_CLOSE_TIMEOUT : NOTIFY_CLOSE_TIMEOUT;

    setTimeout(closeNotification, timeout, notification);
  };

  useEffect(() => {
    emitter.addListener(EVENT.NOTIFY, ({ severity, message }: { severity: SEVERITY; message: string }) =>
      addNotification(severity, message)
    );
    emitter.addListener(EVENT.CLOSE_ALL_NOTIFICATIONS, () => setNotifications([]));

    // cleanup after unmount of component
    return () => {
      emitter.removeAllListeners();
    };
  }, [addNotification]);

  const notificationItems = notifications.map((notification) => {
    return (
      <AnimatePresence key={notification.id}>
        {notification.visible && (
          <div className="NotificationsContainer--alertWrapper">
            <motion.div initial={{ x: 100, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -100, opacity: 0 }}>
              <Alert
                style={{ padding: '1em', width: '50vw', maxWidth: '50rem' }}
                onClick={() => closeNotification(notification)}
                onClose={() => closeNotification(notification)}
                severity={notification.severity}
                data-testid={`notification--${notification.severity}`}
              >
                {notification.message?.toString()}
              </Alert>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    );
  });

  return (
    <div className="NotificationsContainer--wrapper" data-testid="NotificationsContainer--wrapper">
      {notificationItems}
    </div>
  );
};
