import { Button, TextField } from '@mui/material';
import React from 'react';
import '../../cpanel.scss';

interface UnpublishArticleProps {
  articleCmsId: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBtnClick: () => void;
  dataTestId: string;
}

const UnpublishArticle: React.FC<UnpublishArticleProps> = ({ articleCmsId, onChange, onBtnClick, dataTestId }) => {
  return (
    <>
      <section
        className="cpanel-list-table cpanel-list-table--unpublishColumn cpanelGrid__cell"
        data-testid={dataTestId}
      >
        <div>
          <TextField
            className="customWidthTextField--100"
            type="text"
            InputProps={{ disableUnderline: true }}
            placeholder="CMS Artikel ID*"
            variant="standard"
            value={articleCmsId}
            onChange={onChange}
          />
        </div>
        <Button
          className="cpanelUnpublishButton"
          type="submit"
          variant="contained"
          disabled={!articleCmsId}
          onClick={onBtnClick}
        >
          UNPUBLISH ARTICLE
        </Button>
      </section>
    </>
  );
};

export default UnpublishArticle;
