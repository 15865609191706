import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useReducer, useState } from 'react';
import { useCreateDictionaryEntry } from '../../../../hooks/useCreateDictionaryEntry/useCreateDictionaryEntry';
import { DictionaryEntry } from '../../../../models/Dictionary';
import './CreateEntryModal.scss';
import CreateEntryModalActions from './CreateEntryModalActions';
import CreateEntryModalContent from './CreateEntryModalContent';
import {
  createEntryModalReducer,
  CREATE_ENTRY_ACTIONS,
  initialEntries,
  isCompleteEntry,
  NewEntry,
} from './createEntryModalReducer';

export default function CreateEntryModal({
  isOpen,
  onClose,
  searchTerm,
  refetchSearchResults,
}: {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  searchTerm: string;
  refetchSearchResults: () => Promise<QueryObserverResult<DictionaryEntry[], string | Error>>;
}) {
  const [newEntries, dispatchCreateEntry] = useReducer(createEntryModalReducer, initialEntries);
  const [createdEntries, setCreatedEntries] = useState<NewEntry[]>([]);
  const [countCreatedEntries, setCountCreatedEntries] = useState(0);

  const onChangeEntry = (index: number, name: string, value: string | unknown) => {
    dispatchCreateEntry({ type: CREATE_ENTRY_ACTIONS.UPDATE_ENTRY, payload: { index, name, value } });
    setCountCreatedEntries(0);
  };

  const onClearEntry = (index: number) => {
    dispatchCreateEntry({ type: CREATE_ENTRY_ACTIONS.CLEAR_ENTRY, payload: { index } });
  };

  const onClearAllEntries = () => {
    dispatchCreateEntry({ type: CREATE_ENTRY_ACTIONS.INIT });
    setCountCreatedEntries(0);
  };

  const { mutate: createEntry, isPending: isCreatingEntry } = useCreateDictionaryEntry({
    dispatchCreateEntry,
    setCountCreatedEntries,
  });

  const onCreateAllEntries = async () => {
    for (const entry of newEntries) {
      if (isCompleteEntry(entry)) {
        const completeEntry = entry;
        delete completeEntry.error;
        await createEntry(completeEntry);
        setCreatedEntries((prevEntries) => [...prevEntries, completeEntry]);
      }
    }
  };

  const isMatchSearchTerm = () => {
    const matchedSearchTerms = createdEntries.filter((entry) => entry.original.includes(searchTerm));
    return matchedSearchTerms.length !== 0;
  };

  useEffect(() => {
    if (isOpen) {
      setCreatedEntries([]);
    }
  }, [isOpen]);

  const onCloseModal = () => {
    onClose(false);
    onClearAllEntries();

    if (searchTerm.trim().length !== 0 && isMatchSearchTerm()) {
      refetchSearchResults();
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" className="createEntryModal--container">
      <IconButton className="close-icon" onClick={() => onCloseModal()} disableTouchRipple>
        <CloseIcon />
      </IconButton>
      <DialogTitle className="title-header">Neue Einträge hinzufügen</DialogTitle>
      <CreateEntryModalContent
        newEntries={newEntries}
        onChangeEntry={onChangeEntry}
        onClearEntry={onClearEntry}
        dispatchCreateEntry={dispatchCreateEntry}
      />
      <CreateEntryModalActions
        newEntries={newEntries}
        countCreatedEntries={countCreatedEntries}
        onClearAllEntries={onClearAllEntries}
        onCreateAllEntries={onCreateAllEntries}
        isCreatingEntry={isCreatingEntry}
      />
    </Dialog>
  );
}
