import { createContext, useContext } from 'react';
import { BRAND } from '../../models/Article';
import { nullUserState } from './AuthProvider';

export interface IAuthUser {
  authToken: null | string;
  name: null | string;
  refreshToken: null | string;
  roles: null | string[];
}

export interface IAuthState {
  user: IAuthUser;
  userAuthenticated: boolean;
  isError: boolean;
  loading: boolean;
  initialized: boolean;
  startSession: (authorizationCode: string) => Promise<void | string>;
  clearSession: (keepBrand: boolean) => Promise<void>;
  brand: BRAND | null;
  switchBrand: (brandName: BRAND | null) => void;
}

export const initialState: IAuthState = {
  brand: null,
  user: nullUserState,
  isError: false,
  loading: false,
  initialized: false,
  userAuthenticated: false,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  startSession: (_authorizationCode: string) => Promise.resolve(),
  clearSession: (_keepBrand: boolean) => Promise.resolve(),
  switchBrand: (_brandName: BRAND | null) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
};

export const AuthContext = createContext(initialState);

const useAuthContext = () => useContext(AuthContext);

export default AuthContext;

export { useAuthContext };
