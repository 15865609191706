import MenuIcon from '@mui/icons-material/Menu';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { IoCheckboxOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { humanReadableDate, humanReadableTime } from '../../pages/ArticleLandingPage/ArticleJobList/ArticleJobList';
import { StrippedSSMLText } from '../StrippedSSMLText/StrippedSSMLText';
import './ArticlesDragAndDrop.scss';

export type ArticleToSchedule = {
  id: string;
  brand: string;
  title: string;
  category: string;
  synthOrdinal: number;
  scheduledTo?: string;
};
export type ScheduledArticle = Pick<ArticleToSchedule, 'id' | 'scheduledTo'>;

interface ArticlesDragAndDrop {
  articlesSource: ArticleToSchedule[];
  onArticlesChanged: (_: ScheduledArticle[]) => void;
}

const defaultColumns = ['Titel', 'Ressorts', 'Geplant für'];

const pastDay = () => {
  const currentDate = new Date();
  return new Date(currentDate.setDate(currentDate.getDate() - 1)).toISOString();
};

export const setArticlesDateTime = (articles: ArticleToSchedule[], date: string = pastDay()) => {
  return articles.map((article, index) => {
    if (index === 0) {
      return { ...article, scheduledTo: date };
    }

    const dateObject = new Date(date);
    return {
      ...article,
      scheduledTo: new Date(dateObject.setMinutes(dateObject.getMinutes() - 5 * index)).toISOString(),
    };
  });
};

export default function ArticlesDragAndDrop({ articlesSource, onArticlesChanged }: ArticlesDragAndDrop) {
  const [articles, setArticles] = useState<ArticleToSchedule[]>([]);
  const [dragItemIndex, setDragItemIndex] = useState<number>();
  const [dragOverItemIndex, setDragOverItemIndex] = useState<number>();
  const [opacity, setOpacity] = useState<string>('1');
  const [dateTimeField, setDateTimeField] = useState(false);
  const [dateTimeEdited, setDateTimeEdited] = useState<string>(pastDay());

  const hasLoaded = useRef(false);

  useEffect(() => {
    const articlesWithDefaultDate = setArticlesDateTime(articlesSource);
    setArticles(articlesWithDefaultDate);
  }, [articlesSource]);

  useEffect(() => {
    if (!articles.length) return;

    if (!hasLoaded.current) {
      const articlesNormalized = articles.map((article) => ({
        id: article.id,
        scheduledTo: article.scheduledTo,
        synthOrdinal: article.synthOrdinal,
      }));

      onArticlesChanged(articlesNormalized);
      hasLoaded.current = true;
    }
  }, [articles, onArticlesChanged]);

  useEffect(() => {
    if (hasLoaded.current) {
      const articlesNormalized = articles.map((article) => ({
        id: article.id,
        scheduledTo: article.scheduledTo,
        synthOrdinal: article.synthOrdinal,
      }));

      onArticlesChanged(articlesNormalized);
    }
  }, [articles]);

  const handleDragStart = (index: number) => {
    setOpacity('0.4');
    setDragItemIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement & { preventDefault: () => void }>) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    const _articles = [...articles];
    const dragItem = _articles.splice(dragItemIndex as number, 1);
    _articles.splice(dragOverItemIndex as number, 0, dragItem[0]);
    const articlesUpdated = setArticlesDateTime(_articles, dateTimeEdited);

    setArticles(articlesUpdated);
  };

  const handleDragEnter = (index: number) => {
    setDragOverItemIndex(index);
  };

  const handleDragEnd = () => {
    setOpacity('1');
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  const onTimeChange = (changedDate: string, event?: KeyboardEvent) => {
    if (event && event.key !== 'Enter') return;

    const updatedArticles = setArticlesDateTime(articles, new Date(changedDate).toISOString());
    setArticles(updatedArticles);
    setDateTimeField(false);
  };

  const dateTimeInputCheck = (event: ChangeEvent<HTMLInputElement>) => {
    let date = event.target.value;

    if (date) {
      const dateArr = date.split('-');
      const year = dateArr[0];

      if (year && year.length > 4) {
        dateArr[0] = year.substring(0, year.length - 1);
        date = dateArr.join('-');
      }
    }

    setDateTimeEdited(date);
  };

  const convertToCalendarFormat = (dateTime: string) => {
    if (!dateTime) return '';

    const dateObj = new Date(dateTime);
    const timezoneOffset = dateObj.getTimezoneOffset() * 60000;
    const date = new Date(dateObj.getTime() - timezoneOffset).toISOString().split('T')[0];
    const time = humanReadableTime(dateObj.getTime());
    return `${date}T${time}`;
  };

  return (
    <div className="table-drag-and-drop-wrapper">
      <table>
        <thead>
          <tr>
            <th />
            {defaultColumns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {articles.map((article, index) => {
            const isFirstItem = index === 0;
            const isFirstItemReadOnly = isFirstItem && !dateTimeField;

            return (
              <tr
                key={article.id}
                data-testid={`article-${article.id}`}
                style={{ opacity: `${opacity}` }}
                className={dragOverItemIndex === index ? 'list-item next-position' : 'list-item'}
                draggable={articles.length > 1}
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop()}
                onDragEnter={() => handleDragEnter(index)}
                onDragEnd={handleDragEnd}
              >
                <td>
                  <MenuIcon style={{ color: '#7a7878' }} />
                </td>
                <td>
                  <Link
                    className="articleDragAndDrop__link"
                    target="_blank"
                    data-testid={`articleDragAndDrop-link-${article.id}`}
                    to={{
                      pathname: `/${article.brand}/${article.id}`,
                    }}
                    state={article}
                  >
                    <StrippedSSMLText>{article.title || '(Titel fehlt)'}</StrippedSSMLText>
                  </Link>
                </td>
                <td data-testid={`articleDragAndDrop-category-${article.id}`}>{article.category}</td>
                <td>
                  <div className="scheduled-column" data-testid={`articleDragAndDrop-date-${article.id}`}>
                    {(!isFirstItem || isFirstItemReadOnly) && (
                      <div onClick={() => isFirstItem && setDateTimeField(true)}>
                        {humanReadableTime(new Date(article.scheduledTo!).getTime())} Uhr ,{' '}
                        {humanReadableDate(new Date(article.scheduledTo!).getTime())}
                      </div>
                    )}
                    {isFirstItemReadOnly && (
                      <FiEdit
                        data-testid="articleDragAndDrop-editDateTime"
                        className="edit"
                        color="gray"
                        size={20}
                        onClick={() => setDateTimeField(true)}
                      />
                    )}
                    {isFirstItem && dateTimeField && (
                      <>
                        <div className="material-textfield">
                          <input
                            type="datetime-local"
                            data-testid="articleDragAndDrop-datePicker"
                            onInput={dateTimeInputCheck}
                            onChange={dateTimeInputCheck}
                            onKeyPress={(event) => onTimeChange(dateTimeEdited, event)}
                            value={convertToCalendarFormat(dateTimeEdited)}
                          />
                        </div>
                        <IoCheckboxOutline
                          data-testid="articleDragAndDrop-setDateTime"
                          color="#3A5EA7"
                          size={30}
                          style={{ marginTop: 4 }}
                          onClick={() => onTimeChange(dateTimeEdited)}
                        />
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
