import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Alert, CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { ChangeEvent } from 'react';
import { NotificationService } from '../../../../components/Notifications/Notifications';
import { TypeLabels } from '../../../../models/Dictionary';
import { useDictionaryContext } from '../store/DictionaryContext';
import { DictionaryEntryField } from '../store/DictionaryProvider';
import { DictionaryBucketEntry } from '../store/DictionaryReducer';
import './DictionaryEntry.scss';

export type DictionaryEntryProps = {
  entry: DictionaryBucketEntry;
  handleSave: (entry: DictionaryBucketEntry) => Promise<boolean>;
};

export const DictionaryEntry = ({ entry, handleSave }: DictionaryEntryProps) => {
  const { changeEntry, removeEntry } = useDictionaryContext();

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name as DictionaryEntryField;

    changeEntry(entry.id, {
      [name]: value,
    });
  };

  const handleOnSave = async () => {
    const success = await handleSave(entry);
    if (success) {
      NotificationService.success(`"${entry.original}" wurde im Wörterbuch gespeichert!`);
    }
  };

  return (
    <div className="dictionaryEntry" data-testid="DictionaryEntry--row">
      <div className="dictionaryEntry__item">
        <TextField
          error={!!entry.error}
          value={entry.original}
          name={DictionaryEntryField.ORIGINAL}
          label="Original"
          variant="outlined"
          className="dictionaryEntry__input"
          onChange={handleOnChange}
          inputProps={{ 'data-testid': 'DictionaryEntry--originalInput' }}
          disabled={entry.loading}
          autoComplete="off"
        />
      </div>

      <div className="dictionaryEntry__item">
        <TextField
          error={!!entry.error}
          data-testid="DictionaryEntry--typeSelect"
          select
          variant="outlined"
          fullWidth={true}
          value={entry.type}
          className="dictionaryEntry__input"
          onChange={handleOnChange}
          inputProps={{
            name: DictionaryEntryField.TYPE,
            id: 'type-dropDown',
            'data-testid': 'DictionaryEntry--typeSelect--items',
          }}
          disabled={entry.loading}
        >
          {Object.entries(TypeLabels).map((entry) => {
            return (
              <MenuItem key={entry[0]} value={entry[0]} data-testid="DictionaryEntry--typeSelectMenuItem">
                {entry[1]}
              </MenuItem>
            );
          })}
        </TextField>
      </div>

      <div className="dictionaryEntry__item">
        <TextField
          error={!!entry.error}
          value={entry.adaption}
          name={DictionaryEntryField.ADAPTION}
          label="Ersetzung"
          variant="outlined"
          onChange={handleOnChange}
          className="dictionaryEntry__input"
          inputProps={{ 'data-testid': 'DictionaryEntry--adaptionInput' }}
          disabled={entry.loading}
          autoComplete="off"
        />
      </div>

      <div className="dictionaryEntry__item dictionaryEntry__actions">
        {entry.loading ? (
          <CircularProgress
            data-testid="DictionaryEntry--loading"
            style={{
              height: '15px',
              width: '15px',
              marginRight: '15px',
            }}
          />
        ) : (
          <>
            <IconButton
              aria-label="cancel"
              color="primary"
              onClick={() => removeEntry(entry.id)}
              data-testid="DictionaryEntry--cancelButton"
            >
              <DeleteOutlinedIcon />
            </IconButton>
            <Button
              color="primary"
              className="dictionaryEntry__saveEntry"
              variant="outlined"
              aria-label="Save entry"
              onClick={handleOnSave}
              disabled={!entry.savable}
              data-testid="DictionaryEntry--submitButton"
            >
              <DoneOutlinedIcon />
            </Button>
          </>
        )}
      </div>

      {entry.error && (
        <div className="dictionaryEntry__error">
          <Alert className="dictionaryEntry__alert" severity="error" data-testid="DictionaryEntry--error">
            {entry.error}
          </Alert>
        </div>
      )}
    </div>
  );
};
