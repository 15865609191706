export const strippedSsmlTagsRegex = new RegExp('<\\/?\\w+\\s?([^<]*)>', 'g');
export const ssmlTagsRemover = (text: string) => text.replace(strippedSsmlTagsRegex, '');

export const StrippedSSMLText = ({ children: text }: { children?: string }) => {
  if (!text) {
    return null;
  }

  const replaced = ssmlTagsRemover(text);
  return <>{replaced}</>;
};
