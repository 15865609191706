import { createRef, memo, useEffect, useState } from 'react';
import { default as AudioPlayer, default as H5AudioPlayer, RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';

import Forward10Icon from '@mui/icons-material/Forward10';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Replay10Icon from '@mui/icons-material/Replay10';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { useLocalStorage } from '../../hooks';
import { BRAND } from '../../models/Article';
import './AudioPlayer.scss';

export const QC_PLAYBACK_SPEED = 'qc_config_playbackspeed_';
export const QC_PLAYBACK_SPEED_DEFAULT = 1;
export const QC_AUDIO_PLAYER_VOLUME = {
  localStorageKey: 'qc_config_volume_level',
  defaultVolumeLevel: 1,
};

type ParrotAudioPlayerProps = {
  url?: string;
  brand?: BRAND;
  downloadable?: boolean;
  isPlaygroundPlayer?: boolean;
  isPlaybackRateChangeable?: boolean;
  onAudioProgress?: (progress: number) => void;
};
const ParrotAudioPlayer = memo(
  ({
    url,
    brand,
    downloadable = true,
    isPlaygroundPlayer = false,
    isPlaybackRateChangeable = true,
    onAudioProgress,
  }: ParrotAudioPlayerProps) => {
    const playerRef = createRef<H5AudioPlayer>();
    const playbackSpeedLocalStorageKey = brand ? QC_PLAYBACK_SPEED + brand : null;
    const [playbackRate, setPlaybackRate] = useLocalStorage<number>(
      playbackSpeedLocalStorageKey,
      QC_PLAYBACK_SPEED_DEFAULT
    );
    const [volumeLevel, setVolumeLevel] = useLocalStorage<number>(
      QC_AUDIO_PLAYER_VOLUME.localStorageKey,
      QC_AUDIO_PLAYER_VOLUME.defaultVolumeLevel
    );

    const [volumeLevelCustomBar, setVolumeLevelCustomBar] = useState(volumeLevel);

    const handlePlaybackRate = (value: string) => {
      setPlaybackRate(Number(value));
    };

    function handleAudioProgress(event: Event) {
      const audio = event.target as HTMLAudioElement;
      if (url && audio && onAudioProgress) {
        onAudioProgress(audio.currentTime);
      }
    }

    useEffect(() => {
      if (playerRef.current?.audio.current) {
        playerRef.current.audio.current.playbackRate = isPlaybackRateChangeable
          ? playbackRate
          : QC_PLAYBACK_SPEED_DEFAULT;
      }
    }, [playerRef, playbackRate]);

    const playbackSpeedControls = (
      <select
        onChange={(event) => handlePlaybackRate(event.target.value)}
        value={playbackRate}
        className={'audioPlayer--speedSelector'}
        data-testid="audio-player--playbackspeed"
      >
        <option value={0.75}>0.75x</option>
        <option value={1}>1x</option>
        <option value={1.25}>1.25x</option>
        <option value={1.5}>1.5x</option>
        <option value={1.75}>1.75x</option>
        <option value={2}>2x</option>
      </select>
    );

    const handleVolumeChangeSlider = (volume: number) => {
      const volumeAdjusted = volume / 100;

      playerRef.current!.audio.current!.volume = volumeAdjusted;
      setVolumeLevelCustomBar(volumeAdjusted);
    };

    function onVolumeChange(event: Event) {
      const audioElem = event.target as HTMLAudioElement;
      const volume = audioElem.volume;

      setVolumeLevel(volume);

      if (volume !== volumeLevelCustomBar) {
        setVolumeLevelCustomBar(volume);
      }
    }

    return (
      <AudioPlayer
        volume={volumeLevelCustomBar}
        ref={playerRef}
        onListen={handleAudioProgress}
        onVolumeChange={onVolumeChange}
        className={`audioPlayer ${!url ? 'audioPlayer--inactive' : ''} ${
          isPlaygroundPlayer ? 'audioPlayer--playground' : ''
        }`}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        src={url}
        showJumpControls={!isPlaygroundPlayer}
        customAdditionalControls={isPlaybackRateChangeable ? [playbackSpeedControls] : undefined}
        customControlsSection={[]}
        customVolumeControls={[]}
        customProgressBarSection={[
          isPlaygroundPlayer ? <></> : RHAP_UI.VOLUME,
          <div key="custom-slider" className="custom-slider-volume">
            <input
              type="range"
              min="0"
              max="100"
              step="1"
              value={volumeLevelCustomBar * 100}
              onChange={(e) => handleVolumeChangeSlider(Number(e.target.value))}
            />
          </div>,
          isPlaybackRateChangeable ? RHAP_UI.ADDITIONAL_CONTROLS : <></>,
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.DURATION,
          downloadable ? (
            <a href={url} download>
              <div className="audioPlayer--downloadButton">
                <SaveAltOutlinedIcon />
              </div>
            </a>
          ) : (
            <></>
          ),
        ]}
        customIcons={{
          rewind: <Replay10Icon fontSize="small" className="audioPlayer--rewindButton" />,
          play: <PlayArrowIcon className="audioPlayer--playButton" />,
          pause: <PauseIcon className="audioPlayer--pauseButton" />,
          forward: <Forward10Icon fontSize="small" className="audioPlayer--forwardButton" />,
        }}
      />
    );
  }
);

export default ParrotAudioPlayer;
