import { Alert, Grid } from '@mui/material';

export default function ErrorBox({ error }: { error: string | Error }) {
  return (
    <Grid item xs={12}>
      <Alert severity="error" data-testid="dictionaryEntry--error">
        {error as string}
      </Alert>
    </Grid>
  );
}
