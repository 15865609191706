import { DictionaryEntry, DictionaryEntryType } from '../models/Dictionary';
import { NewEntry } from '../pages/Dictionary/components/CreateEntryModal/createEntryModalReducer';
import { httpClient } from './Http/HttpClient';
import { handleUnknownHttpError, HttpErrorEvent, IHttpErrorResponse } from './Http/HttpErrorHandlers';

export interface updateEntryPayload {
  type: DictionaryEntryType;
  adaption: string;
}
interface createEntryPayload {
  original: string;
  type: DictionaryEntryType;
  adaption: string;
}

type DictionaryEntryResponse = {
  data: {
    entries: DictionaryEntry[];
  };
};

type UpdatedDictionaryEntryResponse = {
  data: DictionaryEntry;
};

class DictionaryService {
  static url = '/dictionary-entries';

  searchList(searchTermValue: string): Promise<Array<DictionaryEntry | never>> {
    // without `encodeURIComponent` "LGBTQIA+-" -> "LGBTQIA -"
    return httpClient
      .get<DictionaryEntryResponse>(`${DictionaryService.url}?searchTerm=${encodeURIComponent(searchTermValue)}`)
      .then((res) => res.data.entries)
      .catch((error: IHttpErrorResponse) => {
        return Promise.reject(handleUnknownHttpError(error, 'Search Dictionary entries'));
      });
  }

  updateEntry(original: string, update: updateEntryPayload): Promise<DictionaryEntry> {
    const payload = {
      ...update,
      adaption: update.adaption.trim(),
    };
    return httpClient
      .put<UpdatedDictionaryEntryResponse>(`${DictionaryService.url}/${encodeURIComponent(original)}`, payload)
      .then((res) => res.data)
      .catch((error: IHttpErrorResponse) => {
        if (HttpErrorEvent.ERROR_KEY_OBJECT_DATA_INVALID === error.errorKey) {
          return Promise.reject(
            `Validierung des Wörterbucheintrags fehlgeschlagen. Unerlaubte Zeichen "${
              error?.data?.fieldError || ''
            }" in Field "${error?.data?.fieldName || ''}".`
          );
        }
        return Promise.reject(handleUnknownHttpError(error, 'Dictionary Update Entry'));
      });
  }

  createEntry(entry: createEntryPayload | NewEntry): Promise<any> {
    const payload: createEntryPayload | NewEntry = {
      ...entry,
      original: entry.original.trim(),
      adaption: entry.adaption.trim(),
    };
    return httpClient.post(`${DictionaryService.url}`, payload).catch((error: IHttpErrorResponse) => {
      let errorMsg;

      if (error.errorKey === HttpErrorEvent.ERROR_KEY_OBJECT_ALREADY_EXISTS) {
        errorMsg = `Ein Eintrag für "${entry.original}" existiert bereits.`;
      } else if (error.errorKey === HttpErrorEvent.ERROR_KEY_OBJECT_DATA_INVALID) {
        errorMsg = `Validierung des Wörterbucheintrags fehlgeschlagen. Unerlaubte Zeichen "${
          error?.data?.fieldError || ''
        }" in Field "${error?.data?.fieldName || ''}".`;
      } else {
        errorMsg = handleUnknownHttpError(error, 'Dictionary Create Entry');
      }
      return Promise.reject(errorMsg);
    });
  }

  /**
   * @description Every entry in the dictionary is unique. Hence, the `original` property is used as a primary key in the DB
   * @description And we send the `delete` request with the `original` property
   * */
  deleteEntry(original: string) {
    return httpClient
      .delete(`${DictionaryService.url}/${encodeURIComponent(original)}`)
      .catch((error: IHttpErrorResponse) => {
        return Promise.reject(handleUnknownHttpError(error, 'Dictionary Delete Entry'));
      });
  }
}

export default new DictionaryService();
