import { ReactNode, useReducer } from 'react';
import { ArticleStatus, IArticle } from '../../../models/Article';
import { ColumnName } from '../ArticlesFilterHeader/ArticlesFilterHeader';
import ArticlesContext from './ArticlesContext';
import articlesReducer, { ArticlesAction } from './ArticlesReducer';

export interface ArticlesState {
  articles: IArticle[];
  isFormOpen: boolean;
  isModalOpen: boolean;
  loadingMore: boolean;
  checkedArticleIds: Array<string>;
  showConfirmActionDialog: boolean;
  selectedResort: string | null;
  selectedStatus: ArticleStatus | null;
  selectedAction: string | null;
}

export interface ArticlesStateWithActions extends ArticlesState {
  toggleNewArticleForm(showForm: boolean): void;
  toggleAlexaUpdateModal(showModal: boolean): void;
  setLoadingMore(loadMoreArticles: boolean): void;
  setCheckedArticlesIds(articleIds: string[]): void;
  setSelectedAction(selectedAction: string): void;
  setArticles(articles: IArticle[]): void;
  toggleConfirmActionDialog(showDialog: boolean): void;
  setArticlesFilter(filtersCmd: SetArticlesFiltersCmd): void;
  resetArticlesFilter(): void;
  handleArticleSearch(searchResult: IArticle): void;
}

export type SetArticlesFiltersCmd = {
  filterType: ColumnName;
  value: ArticleStatus | string | null;
};

export const initialArticlesState: ArticlesState = {
  articles: [],
  isFormOpen: false,
  isModalOpen: false,
  loadingMore: false,
  checkedArticleIds: [],
  showConfirmActionDialog: false,
  selectedResort: null,
  selectedStatus: null,
  selectedAction: null,
};

type ArticlesProviderProps = {
  children: ReactNode;
  initialState?: ArticlesState;
};
export default function ArticlesProvider({ children, initialState = initialArticlesState }: ArticlesProviderProps) {
  const [state, dispatch] = useReducer(articlesReducer, initialState);

  const setArticles = (articles: IArticle[]) => {
    dispatch({
      type: ArticlesAction.SET_ARTICLES,
      payload: articles,
    });
  };

  const toggleNewArticleForm = (showForm: boolean) => {
    dispatch({
      type: ArticlesAction.TOGGLE_ARTICLE_FORM,
      payload: showForm,
    });
  };

  const toggleAlexaUpdateModal = (showModal: boolean) => {
    dispatch({
      type: ArticlesAction.TOGGLE_ALEXA_MODAL,
      payload: showModal,
    });
  };

  const setLoadingMore = (loadMoreArticles: boolean) => {
    dispatch({
      type: ArticlesAction.SET_LOAD_MORE_ARTICLES,
      payload: loadMoreArticles,
    });
  };

  const setCheckedArticlesIds = (articleIds: string[]) => {
    dispatch({
      type: ArticlesAction.SET_CHECKED_ARTICLES,
      payload: articleIds,
    });
  };

  const setSelectedAction = (selectedAction: string) => {
    dispatch({
      type: ArticlesAction.SET_SELECTED_ACTION,
      payload: selectedAction,
    });
  };

  const toggleConfirmActionDialog = (showDialog: boolean) => {
    dispatch({
      type: ArticlesAction.TOGGLE_CONFIRM_DIALOG,
      payload: showDialog,
    });
  };

  const setArticlesFilter = (filtersCmd: SetArticlesFiltersCmd) => {
    dispatch({
      type: ArticlesAction.SET_ARTICLES_FILTERS,
      payload: filtersCmd,
    });
  };
  const resetArticlesFilter = () => {
    dispatch({
      type: ArticlesAction.RESET_ARTICLES_FILTERS,
    });
  };

  const handleArticleSearch = (searchResult: IArticle) => {
    dispatch({
      type: ArticlesAction.SEARCH_ARTICLES,
      payload: searchResult,
    });
  };

  const providerState = {
    ...state,
    setArticles,
    toggleNewArticleForm,
    toggleAlexaUpdateModal,
    setLoadingMore,
    setCheckedArticlesIds,
    setSelectedAction,
    toggleConfirmActionDialog,
    setArticlesFilter,
    resetArticlesFilter,
    handleArticleSearch,
  };

  return <ArticlesContext.Provider value={providerState}>{children}</ArticlesContext.Provider>;
}
