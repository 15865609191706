import ContentLoader from 'react-content-loader';
import '../ArticleHeader/ArticleHeader.scss';

const ArticleDetailsSkeleton = () => {
  return (
    <div data-testid="articleDetailsSkeleton-container">
      <div className="articleDetails-audio-player-container">
        <div className="audioPlayer rhap_container">
          <ContentLoader height="55px" width="100%">
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="43px" />
          </ContentLoader>
        </div>
      </div>

      <div className="articleDetails-header-container">
        <header className="articleDetails-header" data-testid="article-header">
          <ContentLoader height="80px" width="100%" backgroundColor="#FFF">
            <rect x="0" y="10" rx="5" ry="5" width="150" height="60" />
            <rect x="200" y="10" rx="5" ry="5" width="calc(100% - 200px)" height="60" />
          </ContentLoader>
        </header>
      </div>

      <section className="articleDetails--body" data-testid="articleDetails-body">
        <div className="articleDetails--body__actions-wrapper" data-testid="articleDetails-body-actions-wrapper">
          <div className="articleDetails--body__actions" style={{ marginBottom: -25 }}></div>
        </div>

        <div className="articleJobDetails-text-wrapper" style={{ paddingBottom: 18 }}>
          <ContentLoader height="150px" width="100%">
            <rect x="0" y="10" rx="5" ry="5" width="100%" height="140" />
          </ContentLoader>
        </div>
      </section>
    </div>
  );
};

export default ArticleDetailsSkeleton;
