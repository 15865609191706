import { Unpublished } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import PodcastIcon from '@mui/icons-material/Podcasts';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { ArticleSearch } from '../../../components/ArticleSearch/ArticleSearch';
import BrowserNotificationToggler from '../../../components/BrowserNotificationToggler/BrowserNotificationToggler';
import { ArticleStatus } from '../../../models/Article';
import { SELECT_ACTIONS } from '../ArticleActionDialog/ArticleActionDialog';
import { useArticlesContext } from '../store/ArticlesContext';
import './ArticleListHeader.scss';

export type ArticleListHeaderProps = {
  stopPollingForUpdate: () => void;
  onClearSearch: () => void;
};

export default function ArticleListHeader({ stopPollingForUpdate, onClearSearch }: ArticleListHeaderProps) {
  const {
    toggleNewArticleForm,
    toggleAlexaUpdateModal,
    checkedArticleIds,
    toggleConfirmActionDialog,
    articles,
    setSelectedAction,
  } = useArticlesContext();
  const [alexaButtonEnabled, setAlexaButtonEnabled] = useState(false);

  useEffect(() => {
    if (!checkedArticleIds.length) {
      setAlexaButtonEnabled(false);
      return;
    }
    const validCheckedArticles = articles.filter(
      (article) =>
        checkedArticleIds.includes(article.id) && article.status === ArticleStatus.CHECKED && !article.isPremium
    );

    setAlexaButtonEnabled(validCheckedArticles.length === checkedArticleIds.length);
  }, [checkedArticleIds.length]);

  const handleDeleteArticles = () => {
    setSelectedAction(SELECT_ACTIONS.delete);
    toggleConfirmActionDialog(true);
  };

  const handleDepublishArticles = () => {
    setSelectedAction(SELECT_ACTIONS.depublish);
    toggleConfirmActionDialog(true);
  };

  return (
    <header className="article-list-header">
      <div className="article-list-header-content">
        <div className="article-list-header__flex">
          <ArticleSearch stopPollingForUpdate={stopPollingForUpdate} onClearSearch={onClearSearch} />

          <nav className="article-list-header__actions">
            <BrowserNotificationToggler />

            <Tooltip title="Alexa News Update">
              <span>
                <IconButton
                  color="primary"
                  disabled={!alexaButtonEnabled}
                  onClick={() => toggleAlexaUpdateModal(true)}
                  data-testid="alexa-news-update-modal-open-btn"
                >
                  <PodcastIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Depublish selected articles">
              <span>
                <IconButton
                  color="primary"
                  disabled={!checkedArticleIds.length}
                  onClick={handleDepublishArticles}
                  data-testid="depublish-selected-articles-btn"
                >
                  <Unpublished />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Create new article">
              <IconButton
                color="primary"
                onClick={() => toggleNewArticleForm(true)}
                data-testid="new-article-modal-open-btn"
              >
                <NoteAddOutlinedIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete selected articles">
              <span>
                <IconButton
                  color="primary"
                  disabled={!checkedArticleIds.length}
                  onClick={handleDeleteArticles}
                  data-testid="delete-selected-articles-btn"
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </nav>
        </div>
      </div>
    </header>
  );
}
