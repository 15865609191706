import InfoWithCallToActionPage from '../InfoWithCallToActionPage/InfoWithCallToActionPage';

const Logout = () => {
  return (
    <InfoWithCallToActionPage
      headline="Bis bald"
      message="Vielen Dank, dass du ara-voices genutzt hast. Schönen Feierabend! &#x270C;"
      linkAction="/"
      linkText="Erneut einloggen"
    />
  );
};

export default Logout;
