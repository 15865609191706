import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { NotificationService } from '../../../components/Notifications/Notifications';
import { ArticleStatus, ArticleStatusToView, BRAND } from '../../../models/Article';
import { getCategories } from '../../../services/ArticleService';
import { useAuthContext } from '../../../services/Auth/AuthContext';
import { useArticlesContext } from '../store/ArticlesContext';
import './ArticlesFilterHeader.scss';

export enum ColumnName {
  RESSORTS = 'ressorts',
  STATUS = 'status',
}

const ColumnTitle = {
  [ColumnName.RESSORTS]: 'Ressorts',
  [ColumnName.STATUS]: 'Status',
} as const;

type FilterItemType = {
  name: string;
  value: string;
};

export const getArticleStatusFilterList = (): FilterItemType[] => {
  return Object.values(ArticleStatus)
    .filter((state) => state != ArticleStatus.NOT_RUN)
    .map((status) => ({
      name: ArticleStatusToView[status],
      value: status,
    }));
};

type ArticlesFilterHeaderProps = {
  columnName: ColumnName;
};
export default function ArticlesFilterHeader({ columnName }: ArticlesFilterHeaderProps) {
  const columnTitle = ColumnTitle[columnName];
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const filterRefElement = useRef<HTMLDivElement>(null);
  // All the filter items
  const [filterItems, setFilterItems] = useState<FilterItemType[]>([]);
  // Filter item that user checks but didn't apply the Filter yet
  const [checkedFilterItem, setCheckedFilterItem] = useState<ArticleStatus | string | null>(null);

  const { setArticlesFilter, selectedStatus, selectedResort } = useArticlesContext();
  const { brand } = useAuthContext();

  const appliedFilterItem = useMemo(() => {
    if (columnName === ColumnName.STATUS) {
      return selectedStatus;
    }
    if (columnName === ColumnName.RESSORTS) {
      return selectedResort;
    }
    return null;
  }, [columnName, selectedStatus, selectedResort]);

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(true);
    }
    resetCheckedItems();
  };

  const handleCloseDropdown = (evt: MouseEvent) => {
    if (filterRefElement.current && !filterRefElement.current.contains(evt.target as Node)) {
      setDropdownOpen(false);
      resetCheckedItems();
    }
  };

  const resetCheckedItems = () => {
    setCheckedFilterItem(appliedFilterItem);
  };

  const handleCheckItem = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedFilterItem(event.target.value);
  };

  const applyFilter = () => {
    setArticlesFilter({
      filterType: columnName,
      value: checkedFilterItem,
    });
    setDropdownOpen(false);
  };

  const resetFilter = () => {
    setArticlesFilter({
      filterType: columnName,
      value: null,
    });

    setCheckedFilterItem(null);
    setDropdownOpen(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', handleCloseDropdown);

    return () => {
      document.body.removeEventListener('click', handleCloseDropdown);
    };
  }, []);

  useEffect(() => {
    if (columnName === ColumnName.STATUS) {
      setFilterItems(getArticleStatusFilterList());
    } else if (columnName === ColumnName.RESSORTS && brand) {
      trackPromise(getCategories(brand as BRAND))
        .then((res) => {
          setFilterItems(res);
        })
        .catch((error) => {
          NotificationService.error(error);
        });
    }
  }, [columnName, brand]);

  return (
    <div
      ref={filterRefElement}
      className={`articles-filter${isDropdownOpen ? ' open' : ''}`}
      data-testid={'articlesFilterWrapper--' + columnName}
    >
      <Button
        className="articles-filter-dropdown-toggler"
        onClick={toggleDropdown}
        startIcon={appliedFilterItem && <ErrorOutlineOutlinedIcon color="primary" />}
        endIcon={isDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        data-testid={'articlesFilterDropdownToggler--' + columnName}
      >
        {columnTitle}
      </Button>
      <div className="articles-filter-dropdown" data-testid={'articlesFilterDropdown--' + columnName}>
        <ul className="articles-filter-dropdown--list" data-testid={'articlesFilterDropdownList--' + columnName}>
          <RadioGroup value={checkedFilterItem} onChange={handleCheckItem}>
            {filterItems.map((filterItem) => (
              <li key={filterItem.value}>
                <FormControlLabel
                  className={`articles-filter-dropdown--list_item ${
                    checkedFilterItem === filterItem.value ? 'active' : ''
                  }`}
                  labelPlacement="start"
                  value={filterItem.value}
                  control={<Radio color="primary" />}
                  label={
                    filterItem.value == ArticleStatus.PRE_CHECKED ? (
                      <div style={{ display: 'flex' }}>
                        <PlaylistAddCheckIcon
                          fontSize="small"
                          data-testid="article-status-badge__icon--auto-published"
                        />
                        {filterItem.name}
                      </div>
                    ) : (
                      filterItem.name
                    )
                  }
                  data-testid={'articlesFilterDropdownListItem--' + columnName}
                />
              </li>
            ))}
          </RadioGroup>
        </ul>
        {filterItems.length > 0 && (
          <div className="articles-filter-dropdown--actions">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={resetFilter}
              disabled={!checkedFilterItem}
              data-testid={'articlesFilterResetButton--' + columnName}
            >
              Zurücksetzen
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={applyFilter}
              disabled={!checkedFilterItem}
              data-testid={'articlesFilterApplyButton--' + columnName}
            >
              anwenden
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
