import { LinkProps, NavLink } from 'react-router-dom';
import './InfoWithCallToActionPage.scss';

type InfoWithCallToActionPageProps = {
  headline?: string;
  message?: string;
  linkText?: string;
  linkAction?: LinkProps['to'];
};

const InfoWithCallToActionPage = ({
  headline = 'Bitte warten...',
  message = '',
  linkText,
  linkAction,
}: InfoWithCallToActionPageProps) => {
  return (
    <div className="infoWithCta">
      {headline && <h1 className="infoWithCta__headline">{headline}</h1>}
      <p className="infoWithCta__text">{message}</p>

      {linkText && linkAction && (
        <NavLink data-testid="page-cta" className="infoWithCta__cta" to={linkAction}>
          {linkText}
        </NavLink>
      )}
    </div>
  );
};

export default InfoWithCallToActionPage;
