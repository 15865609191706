import { ExitToApp } from '@mui/icons-material';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AraVoicesLogo } from 'src/assets/Logos/AraVoicesLogo';
import { BRAND } from 'src/models/Article';
import { useAuthContext } from 'src/services/Auth/AuthContext';
import { validBrands } from 'src/services/Auth/AuthProvider';
import './Header.scss';

const Header = () => {
  const { brand, userAuthenticated, clearSession } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const linkTarget: string =
    location.pathname === `/${brand}/cpanel` || location.pathname === '/dictionary' ? '_self' : '_blank';

  const logout = () => {
    clearSession(false);
    navigate('/logout');
  };

  const isValidBrand = validBrands.includes(brand as BRAND);

  return (
    <div className="header" data-testid="header--wrapper">
      <Link to={`/${brand || ''}`} className="header__logo" data-testid="header--logo">
        <AraVoicesLogo />
      </Link>

      <div className="header__menu">
        {userAuthenticated && (
          <>
            <NavLink
              to={`/${brand || ''}`}
              className={({ isActive, isPending }) =>
                isActive ? 'header__menu--item--active' : isPending ? '' : 'header__menu--item'
              }
              data-testid={`header--home--${brand}`}
              end // prevents nav element being active on child routes -> https://reactrouter.com/en/main/components/nav-link#end
            >
              Home
            </NavLink>

            {isValidBrand && (
              <NavLink
                target={linkTarget}
                className={({ isActive, isPending }) =>
                  isActive ? 'header__menu--item--active' : isPending ? '' : 'header__menu--item'
                }
                to={`/${brand}/cpanel`}
                data-testid="cpanel--link"
              >
                Cpanel
              </NavLink>
            )}

            <NavLink
              target={linkTarget}
              className={({ isActive, isPending }) =>
                isActive ? 'header__menu--item--active' : isPending ? '' : 'header__menu--item'
              }
              to="/dictionary"
              data-testid="dictionary--link"
            >
              Global Dictionary
            </NavLink>

            <div className="header__menu--item">
              <ExitToApp className="header__menu-logout" data-testid="logout--button" onClick={logout} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
