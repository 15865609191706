import jwt_decode from 'jwt-decode';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { BRAND } from '../../models/Article';
import { httpClient } from '../Http/HttpClient';
import AuthContext, { IAuthUser } from './AuthContext';
import { authService } from './AuthService';
import { cognitoService } from './CognitoService';

export interface ICognitoUser {
  at_hash: string;
  aud: string;
  auth_time: number;
  'cognito:groups': string[];
  'cognito:username': string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  sub: string;
  token_use: string;
}

const USER_KEY = 'qc_username';
const AUTH_TOKEN_KEY = 'qc_authToken';
const REFRESH_TOKEN_KEY = 'qc_refreshToken';
const BRAND_KEY = 'qc_brand';
const ROLES_KEY = 'qc_userRoles';

const isValidUser = (user: IAuthUser) => {
  return !!(user.name && user.authToken);
};

export const nullUserState: IAuthUser = {
  name: null,
  authToken: null,
  refreshToken: null,
  roles: null,
};

export const validBrands = [BRAND.WELT, BRAND.BILD, BRAND.SPORTBILD];

const useUserWithLocalStorage = (): [IAuthUser, React.Dispatch<React.SetStateAction<IAuthUser>>] => {
  const initialUser: IAuthUser = {
    name: localStorage.getItem(USER_KEY) || null,
    authToken: localStorage.getItem(AUTH_TOKEN_KEY) || null,
    refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY) || null,
    roles: localStorage.getItem(ROLES_KEY)?.split(',') || null,
  };

  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    user.name ? localStorage.setItem(USER_KEY, user.name) : localStorage.removeItem(USER_KEY);
    user.authToken ? localStorage.setItem(AUTH_TOKEN_KEY, user.authToken) : localStorage.removeItem(AUTH_TOKEN_KEY);
    user.refreshToken
      ? localStorage.setItem(REFRESH_TOKEN_KEY, user.refreshToken)
      : localStorage.removeItem(REFRESH_TOKEN_KEY);
    user.roles ? localStorage.setItem(ROLES_KEY, user.roles.join(',')) : localStorage.removeItem(ROLES_KEY);
  }, [user]);

  return [user, setUser];
};

const useBrand = (): [BRAND | null, React.Dispatch<React.SetStateAction<BRAND | null>>] => {
  const initialBrand = (localStorage.getItem(BRAND_KEY) as BRAND) || null;
  const [brand, setBrand] = useState<BRAND | null>(initialBrand);

  useEffect(() => {
    if (brand) {
      localStorage.setItem(BRAND_KEY, brand);
    } else {
      localStorage.removeItem(BRAND_KEY);
    }
  }, [brand]);

  return [brand, setBrand];
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isError] = useState(false);
  const [user, setUser] = useUserWithLocalStorage();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(isValidUser(user));
  const [brand, setBrand] = useBrand();

  const switchBrand = useCallback(
    (brandName: BRAND | null) => {
      setBrand(brandName);
    },
    [setBrand]
  );

  useEffect(() => {
    if (isValidUser(user)) {
      const path = window.location.pathname;
      const brandInPath = Object.values(BRAND).find((brand) => path.startsWith(`/${brand}`));

      const getBrandFromCognitoGroup = (user: IAuthUser) =>
        validBrands.find((brand) => user?.roles?.some((role) => role.match(`cpanel-${brand}`)));

      const cognitoGroupBrand = getBrandFromCognitoGroup(user);

      if (cognitoGroupBrand) {
        setBrand(cognitoGroupBrand as BRAND);
      }

      if (brandInPath && brand !== brandInPath) {
        setBrand(brandInPath);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user.name && user.authToken) {
      trackPromise(
        authService
          .resumeSession(user.authToken)
          .then(() => {
            setUserAuthenticated(true);
            setInitialized(true);
          })
          .catch(() => {
            authService.clearSession();
            setUser({ ...nullUserState });
            setUserAuthenticated(false);
          })
      );
    }
  }, [user.authToken, user.name]);

  const updateUserFromTokenResponse = (refreshToken: string, idToken: string) => {
    const userFromToken: ICognitoUser = jwt_decode(idToken);

    console.debug('Updating user from tokens data', userFromToken.email, userFromToken['cognito:groups']);
    console.debug('Got new refresh token', !!refreshToken);

    const newUser: IAuthUser = {
      ...user,
      name: userFromToken.email,
      roles: userFromToken['cognito:groups'] || [],
      authToken: idToken,
      refreshToken: refreshToken || user.refreshToken,
    };

    setUser(newUser);
  };

  const startSession = (authorizationCode: string) => {
    setLoading(true);
    return trackPromise(
      cognitoService
        .fetchTokens(authorizationCode)
        .then((res) => {
          updateUserFromTokenResponse(res.refreshToken, res.idToken);
          return res;
        })
        .then((res) => {
          setUserAuthenticated(true);
          return res.idToken;
        })
        .catch(() => {
          return clearSession().finally(() => Promise.reject());
        })
        .finally(() => {
          setLoading(false);
        })
    );
  };

  const clearSession = useCallback((keepBrand = false) => {
    return Promise.resolve(authService.clearSession)
      .then(() => {
        setUser({ ...nullUserState });
        if (!keepBrand) {
          setBrand(null);
        }
      })
      .finally(() => {
        setUserAuthenticated(false);
      });
  }, []);

  const refreshSession = (): Promise<string | void> => {
    if (!user.refreshToken) {
      console.debug('Clearing session...');
      return clearSession();
    }
    setInitialized(false);
    console.debug('Refreshing session...');
    return cognitoService
      .refreshTokens(user.refreshToken)
      .then(({ refreshToken, idToken }) => {
        updateUserFromTokenResponse(refreshToken, idToken);
        setInitialized(true);
        return idToken;
      })
      .catch(() => {
        return clearSession();
      });
  };

  useEffect(() => {
    if (user.refreshToken) {
      httpClient.initUnauthorizedInterceptor(refreshSession);
    } else {
      httpClient.removeExistingUnauthorizedInterceptor();
    }
  }, [user.refreshToken]);

  const memorizedValue = useMemo(
    () => ({
      user,
      userAuthenticated,
      isError,
      loading,
      initialized,
      startSession,
      clearSession,
      brand,
      switchBrand,
    }),
    [brand, clearSession, initialized, isError, loading, startSession, switchBrand, user, userAuthenticated]
  );

  return <AuthContext.Provider value={memorizedValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
