export const AraVoicesLogo = ({ variant = 'tts' }) =>
  // 'tts' ? 'TTS parrot green color' : 'White color'
  variant === 'tts' ? (
    <svg width="60" height="53" viewBox="0 0 60 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.8003 31.084H9.3457C9.3457 39.2032 15.9307 45.8113 24.0878 45.8113C32.2449 45.8113 38.8003 39.2032 38.8003 31.084Z"
        fill="url(#paint0_linear_574_4794)"
      />
      <path
        d="M9.71403 15.9886C11.5441 15.9886 13.0277 14.505 13.0277 12.675C13.0277 10.8449 11.5441 9.36133 9.71403 9.36133C7.88396 9.36133 6.40039 10.8449 6.40039 12.675C6.40039 14.505 7.88396 15.9886 9.71403 15.9886Z"
        fill="url(#paint1_linear_574_4794)"
      />
      <path
        d="M52.7912 29.2431H9.3457C9.3457 17.0457 19.0683 7.15222 31.0551 7.15222C43.0419 7.15222 52.7912 17.0457 52.7912 29.2431Z"
        fill="url(#paint2_linear_574_4794)"
      />
      <mask
        id="mask0_574_4794"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="7"
        width="30"
        height="23"
      >
        <rect x="9.3457" y="7.15222" width="29.4546" height="22.0909" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_574_4794)">
        <path
          d="M52.7912 29.2431H9.3457C9.3457 17.0457 19.0683 7.15222 31.0551 7.15222C43.0419 7.15222 52.7912 17.0457 52.7912 29.2431Z"
          fill="url(#paint3_linear_574_4794)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_574_4794"
          x1="9.22128"
          y1="37.0241"
          x2="22.7879"
          y2="48.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A61A7" />
          <stop offset="1" stopColor="#6DE9EC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_574_4794"
          x1="6.37239"
          y1="12.0344"
          x2="10.8785"
          y2="13.9917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A61A7" />
          <stop offset="1" stopColor="#6DE9EC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_574_4794"
          x1="31.0684"
          y1="7.15222"
          x2="31.0684"
          y2="29.2431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22D2A3" />
          <stop offset="1" stopColor="#6DE9EC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_574_4794"
          x1="9.16218"
          y1="16.0624"
          x2="29.4615"
          y2="33.4034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A61A7" />
          <stop offset="1" stopColor="#6DE9EC" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1869 16.4553H3.47266C3.47266 20.787 6.98582 24.3125 11.3377 24.3125C15.6896 24.3125 19.1869 20.787 19.1869 16.4553Z"
        fill="white"
      />
      <path
        d="M3.66825 8.40168C4.64461 8.40168 5.43611 7.61018 5.43611 6.63382C5.43611 5.65746 4.64461 4.86597 3.66825 4.86597C2.69189 4.86597 1.90039 5.65746 1.90039 6.63382C1.90039 7.61018 2.69189 8.40168 3.66825 8.40168Z"
        fill="white"
      />
      <path
        d="M26.6512 15.4732H3.47266C3.47266 8.96576 8.65977 3.6875 15.0548 3.6875C21.4499 3.6875 26.6512 8.96576 26.6512 15.4732Z"
        fill="white"
      />
      <mask
        id="mask0_574_4222"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="17"
        height="13"
      >
        <rect x="3.47266" y="3.6875" width="15.7143" height="11.7857" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_574_4222)">
        <path
          d="M26.6512 15.4732H3.47266C3.47266 8.96576 8.65977 3.6875 15.0548 3.6875C21.4499 3.6875 26.6512 8.96576 26.6512 15.4732Z"
          fill="white"
        />
      </g>
    </svg>
  );
