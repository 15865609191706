import Button from '@mui/material/Button';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { withStyles } from 'tss-react/mui';
import ArticleStatusBadge from '../../../components/ArticleStatusBadge/ArticleStatusBadge';
import { NotificationService } from '../../../components/Notifications/Notifications';
import { SSMLTextEditor } from '../../../components/SSMLTextEditor/SSMLTextEditor';
import { IArticle } from '../../../models/Article';
import { ArticleDates } from '../ArticleDates/ArticleDates';
import './ArticleHeader.scss';

const CopyArticleIdTooltip: FunctionComponent<TooltipProps> = withStyles(Tooltip, {
  tooltip: {
    fontSize: '14px',
  },
});

const LinkedArticleId = ({ id }: { id: string }) => {
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(id).then(() => NotificationService.info(`Copied ${id} to your clipboard`));
  };

  return (
    <>
      {id && (
        <div className="articleDetails-header-id" data-testid="linkedArticleId">
          <CopyArticleIdTooltip title="Artikel-Id kopieren" enterDelay={300} leaveDelay={200} placement="bottom" arrow>
            <Button color="primary" aria-label="copy article-id" onClick={copyToClipBoard} className="article-id-btn">
              ID: {id}
            </Button>
          </CopyArticleIdTooltip>
        </div>
      )}
    </>
  );
};

type ArticleHeaderProps = {
  article: IArticle;
  readonly: boolean;
  onTitleChange: (value: string) => void;
};
export const ArticleHeader = ({ onTitleChange, readonly, article }: ArticleHeaderProps) => {
  return (
    <header className="articleDetails-header" data-testid="article-header">
      {article.externalId ? (
        <>
          <div className="statusTag">
            <ArticleStatusBadge
              publications={article.publications}
              status={article.status}
              isPremium={article.isPremium}
            />
            <LinkedArticleId id={article.externalId} />
          </div>
          <div className="articleDetails-header-text">
            {
              <SSMLTextEditor
                readonly={readonly}
                text={article.title || '(Titel fehlt)'}
                onTextChange={onTitleChange}
                dataTestId="articleDetails--title"
                className="articleJobDetails-title"
              />
            }

            <div className="articleDetails-header-text-bottom">
              <ArticleDates
                createdAt={article.createdAt}
                updatedAt={article.updatedAt}
                categories={article.categories}
              />
            </div>
          </div>
        </>
      ) : (
        <ContentLoader height="80px" width="100%">
          <rect x="0" y="10" rx="5" ry="5" width="150" height="60" />
          <rect x="200" y="10" rx="5" ry="5" width="calc(100% - 200px)" height="60" />
        </ContentLoader>
      )}
    </header>
  );
};
