import axios, { AxiosError, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export interface IHttpErrorResponse {
  statusCode?: number;
  errorMessage?: string;
  errorKey?: string;
  data?: Record<string, unknown>;
  headers?: RawAxiosResponseHeaders | AxiosResponseHeaders;
  traceId?: string;
}

export enum HttpErrorEvent {
  ERROR_KEY_OBJECT_ALREADY_EXISTS = 'OBJECT_ALREADY_EXISTS',
  ERROR_KEY_OBJECT_DATA_INVALID = 'OBJECT_DATA_INVALID',
  CALL_CANCELED = 'CALL_CANCELED',
  NETWORK_ERROR = 'NETWORK_ERROR',
  OBJECT_NOT_FOUND = 'OBJECT_NOT_FOUND',
}

export const NETWORK_ERROR_MESSAGE = 'Network Error';

export const httpError = ({ statusCode, errorMessage, errorKey, data, headers }: IHttpErrorResponse): IHttpErrorResponse => {
  return {
    statusCode,
    errorMessage,
    errorKey,
    data,
    headers,
    traceId: headers?.['x-amzn-trace-id'],
  };
};

export const throwHttpError = (error: AxiosError): Promise<IHttpErrorResponse> => {
  console.log(error?.message);

  if (axios.isCancel(error)) {
    return Promise.reject<IHttpErrorResponse>(
      httpError({
        statusCode: 200,
        errorMessage: error.message,
        errorKey: HttpErrorEvent.CALL_CANCELED,
      })
    );
  }

  if (NETWORK_ERROR_MESSAGE === (error as AxiosError)?.message) {
    return Promise.reject<IHttpErrorResponse>(
      httpError({
        errorMessage: 'Leider ist ein Fehler aufgetreten. Bitte aktualisiere die Seite.',
        errorKey: HttpErrorEvent.NETWORK_ERROR,
      })
    );
  }

  const response = ((error as AxiosError).response || {}) as AxiosResponse;

  return Promise.reject<IHttpErrorResponse>(
    httpError({
      statusCode: response?.status,
      errorMessage: response?.data?.errorMessage,
      errorKey: response?.data?.errorKey,
      data: response?.data?.errorData,
      headers: response?.headers,
    })
  );
};

export const handleUnknownHttpError = (error: IHttpErrorResponse, callerName: string) => {
  console.error({ handleUnknownHttpError: error });

  if (error?.errorMessage) {
    return error.errorMessage;
  }

  return `
            ${callerName} encountered an error:
            - Error event: "${error?.errorKey || 'Unknown'}".
            - Error-code = "${error?.statusCode || 'unknown'}".
            - Message = "${error?.errorMessage || 'No message'}".
            - Additional info = "${error?.data || 'No info'}"`;
};

export const handleHttpErrorCustomMessage = (error: IHttpErrorResponse) => {
  console.error({ httpError: error });

  if (error?.errorMessage) {
    const REMOVE_BRACKETS = /\[.*?\] /g;
    return error.errorMessage.replace(REMOVE_BRACKETS, '');
  }
  return JSON.stringify(error?.data);
};