import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import {
  CREATE_ENTRY_ACTIONS,
  NewEntry,
} from '../../pages/Dictionary/components/CreateEntryModal/createEntryModalReducer';
import DictionaryService from '../../services/DictionaryService';

export function useCreateDictionaryEntry({
  dispatchCreateEntry,
  setCountCreatedEntries,
}: {
  dispatchCreateEntry: Dispatch<{ type: CREATE_ENTRY_ACTIONS; payload: { original: string; error?: string | Error } }>;
  setCountCreatedEntries: Dispatch<SetStateAction<number>>;
}) {
  return useMutation({
    mutationKey: ['createEntry'],
    mutationFn: (entry: NewEntry): Promise<{ data: NewEntry }> => DictionaryService.createEntry(entry),

    onSuccess: ({ data: entry }) => {
      setCountCreatedEntries((prev) => prev + 1);
      dispatchCreateEntry({ type: CREATE_ENTRY_ACTIONS.REMOVE_CREATED_ENTRY, payload: { original: entry.original } });
      return;
    },

    onError: (error, variables: NewEntry) => {
      dispatchCreateEntry({
        type: CREATE_ENTRY_ACTIONS.ADD_ERROR_TO_ENTRY,
        payload: { original: variables.original, error },
      });
    },
  }) as UseMutationResult;
}
