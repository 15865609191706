import { Unpublished } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Clear';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { withStyles } from 'tss-react/mui';
import { ArticleStatus, IArticle, PublicationProgress } from '../../models/Article';
import { getBadgeOptions } from './articleStatus';
import './ArticleStatusBadge.scss';

const StatusTooltip: React.FunctionComponent<TooltipProps> = withStyles(Tooltip, {
  tooltip: {
    fontSize: '14px',
  },
});

export const CSS_CLASS_NAMES = {
  RUNNING: 'article-status-badge--RUNNING',
  FAILURE: 'article-status-badge--FAILURE',
  PUBLICATION_SUCCESS: 'article-status-badge--PUBLICATION_SUCCESS',
  SYNTHESIS_SUCCESS: 'article-status-badge--SYNTHESIS_SUCCESS',
  PREMIUM: 'article-status-badge--IS_PREMIUM',
  DEPUBLISHED: 'article-status-badge--DEPUBLISHED',
};

const StatusIcon = ({ status }: { status: ArticleStatus }) => {
  let statusIcon = null;

  switch (status) {
    case ArticleStatus.FAILED:
      statusIcon = <PriorityHighIcon data-testid="article-status-badge__icon--failed" />;
      break;
    case ArticleStatus.PRE_CHECKED:
      statusIcon = <PlaylistAddCheckIcon data-testid="article-status-badge__icon--auto-published" />;
      break;
    case ArticleStatus.RUNNING:
    case ArticleStatus.PUBLISHING:
      statusIcon = <CachedIcon className={'statusIcon-spin'} data-testid="article-status-badge__icon--running" />;
      break;
  }

  if (!statusIcon) return null;

  return (
    <div data-testid="article-status-badge__icon" className="article-status-badge__icon">
      {statusIcon}
    </div>
  );
};

export type ArticleStatusBadgeProps = {
  status: ArticleStatus;
  dataTestId?: string;
  publications: IArticle['publications'];
  isPremium: boolean;
};

const ArticleStatusBadge = ({
  publications,
  dataTestId = 'article-status-badge',
  status,
  isPremium,
}: ArticleStatusBadgeProps) => {
  const { label, cssClass } = getBadgeOptions(status);

  const podcastsTooltipContent = publications?.length ? (
    <React.Fragment>
      <Typography>Podcasts:</Typography>
      {publications.map((publication) => (
        <p key={publication.podcastId} className="podcastId-tooltip-item">
          {publication.podcastId}

          {publication.progress === PublicationProgress.SUCCESS && (
            <CheckCircleIcon fontSize={'small'} style={{ color: '#22D2A3' }} />
          )}
          {[PublicationProgress.FAILURE, PublicationProgress.DEPUBLISH_FAILURE].includes(publication.progress) && (
            <CancelIcon fontSize={'small'} style={{ color: '#F02285' }} />
          )}
          {[PublicationProgress.CMS_DEPUBLISHED, PublicationProgress.USER_DEPUBLISHED].includes(
            publication.progress
          ) && <Unpublished fontSize={'small'} style={{ color: '#9e9e9e' }} />}
        </p>
      ))}
    </React.Fragment>
  ) : (
    ''
  );

  return (
    <div className="article-status-badges">
      <StatusTooltip title={podcastsTooltipContent} enterDelay={300} leaveDelay={50} placement="top" arrow>
        <div
          data-testid={dataTestId}
          className={`article-status-badge ${cssClass} ${isPremium && 'article-status-badge--has_premium_badge'}`}
        >
          {isPremium && (
            <WorkspacePremiumOutlinedIcon
              className="article-status-badge__premium-icon"
              data-testid="article-status-badge__icon--premium"
            />
          )}
          <StatusIcon status={status} />
          <div className="article-status-badge__label">{label}</div>
          <span data-testid="article-status-badge__podcastIds--tooltip" style={{ display: 'none' }}>
            {podcastsTooltipContent}
          </span>
        </div>
      </StatusTooltip>
    </div>
  );
};

export default ArticleStatusBadge;
