import {withStyles} from 'tss-react/mui';
import Tooltip, {TooltipProps} from '@mui/material/Tooltip';
import {IArticle} from '../../../models/Article';
import {sanitizeArticleCategories} from '../../ArticleLandingPage/ArticleJobList/ArticleJobList';
import React from 'react';

const ArticleCategoryTooltip: React.FunctionComponent<TooltipProps> = withStyles(Tooltip, {
  tooltip: {
    fontSize: '14px'
  }
});

const humanReadableDate = (date: number) => {
  if (!date) return '';
  const dateObj = new Date(date);

  const formattedDate = dateObj.toLocaleDateString('de-DE').replace(/\./g, '/');
  const formattedTime = dateObj.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Berlin',
  });
  return `${formattedTime} Uhr, ${formattedDate}`;
};

type ArticleDatesProps = {
  createdAt: IArticle['createdAt'];
  updatedAt: IArticle['updatedAt'];
  categories: IArticle['categories'];
};
export const ArticleDates = ({createdAt, updatedAt, categories}: ArticleDatesProps) => {
  return (
    <div className="articleDetails-header-infos" data-testid="articleDates">
      {categories && (
        <ArticleCategoryTooltip title={categories.join()} enterDelay={300} leaveDelay={200} placement="bottom" arrow>
          <span className="article-header-category" data-testid="articleDate-categories">
            {sanitizeArticleCategories(categories)}
          </span>
        </ArticleCategoryTooltip>
      )}
      {createdAt && (
        <span className="article-header-date" data-testid="articleDate-created">
          Erstellt: {humanReadableDate(createdAt)}
        </span>
      )}
      {updatedAt && (
        <span className="article-header-date" data-testid="articleDate-updated">
          Aktualisiert: {humanReadableDate(updatedAt)}
        </span>
      )}
    </div>
  );
};