const generateApiUrlForStage = (stage: string) => {
  return stage === 'prod' ? 'https://article-api.tts.asideas.de' : `https://${stage}.article-api.tts.asideas.de`;
};

export const getStage = (url: string): string => {
  let stage = 'prod';
  const servedInSubdomainRegex = /^https:\/\/([a-z\-0-9]+)\.cockpit\.tts\.asideas\.de/i;
  const matches = servedInSubdomainRegex.exec(url);
  if (matches) stage = matches[1];

  return stage;
};

export const deriveApiUrl = (url: string) => {
  const apiInLocalstorage = localStorage.getItem('qc_api_url');
  if (apiInLocalstorage) {
    return apiInLocalstorage;
  }

  // when running locally we want to work against production
  const servedFromLocalhostRegex = /^http:\/\/localhost*/i;
  if (servedFromLocalhostRegex.test(url)) {
    // return "http://localhost:8080"
    return generateApiUrlForStage('staging');
  }

  return generateApiUrlForStage(getStage(url));
};
