import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import '../../cpanel.scss';

type CustomTooltipProps = Pick<TooltipProps, 'title' | 'placement'>;

interface HeaderProps {
  title: string;
  tooltip?: CustomTooltipProps;
  dataTestId: string;
}

const Header: React.FC<HeaderProps> = ({ title, tooltip, dataTestId }) => {
  return (
    <header className="cpanel-list-header" data-testid={dataTestId}>
      <div className="cpanel-list-header-content">
        <h4>{title}</h4>
        {tooltip && tooltip.title && (
          <Tooltip title={tooltip.title} placement={tooltip.placement || 'top-start'}>
            <IconButton
              className="iconButton--noVerticalPadding"
              color="primary"
              data-testid={dataTestId + '-info-btn'}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </header>
  );
};

export default Header;
