import createInlineToolbarPlugin, { InlineToolBarPlugin } from '@draft-js-plugins/inline-toolbar';
import { ButtonBase } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDictionaryContext } from '../../../pages/ArticleDetails/DictionaryBucket/store/DictionaryContext';
import './EditorContextMenu.scss';

export const AddToDictionaryButton = () => {
  const { addEntry } = useDictionaryContext();
  const handleClick = () => {
    const selection = window?.getSelection()?.toString();
    addEntry(selection || '', false);
  };

  return (
    <ButtonBase
      className="buttonBase"
      onMouseDown={(e) => e.preventDefault()}
      onClick={handleClick}
      data-testid="DictionaryBucket--addSelection"
    >
      <AddIcon fontSize="small" />
    </ButtonBase>
  );
};

type articleTextEditorContextMenuFactoryReturnType = {
  inlineToolbarPlugin: InlineToolBarPlugin;
  ArticleTextEditorContextMenu: () => JSX.Element;
};
export const articleTextEditorContextMenuFactory = (): articleTextEditorContextMenuFactoryReturnType => {
  const inlineToolbarPlugin = createInlineToolbarPlugin();
  const InlineToolbar = inlineToolbarPlugin.InlineToolbar;

  const ArticleTextEditorContextMenu = () => <InlineToolbar>{() => <AddToDictionaryButton />}</InlineToolbar>;

  return { inlineToolbarPlugin, ArticleTextEditorContextMenu };
};
