import Button from '@mui/material/Button';
import React, { memo, useCallback, useState } from 'react';
import ParrotAudioPlayer from '../../../components/AudioPlayer/AudioPlayer';
import { playgroundSynthesis } from '../../../services/ArticleService';
import './PlaygroundDrawer.scss';

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { NotificationService } from '../../../components/Notifications/Notifications';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AraVoicesLogo } from '../../../assets/Logos/AraVoicesLogo';
import { BRAND } from '../../../models/Article';
import { useAuthContext } from '../../../services/Auth/AuthContext';

const axiosRetryClient = axios.create();

const SYNTH_URL_AVAILABLE_RETRIES = 8;

axiosRetry(axiosRetryClient, {
  retries: SYNTH_URL_AVAILABLE_RETRIES,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.response?.status !== 200;
  },
});

async function waitForSynthesisToBeAvailable(url: string) {
  await axiosRetryClient.get(url, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'audio/wav',
    },
  });
}

const PlaygroundDrawer = memo(() => {
  const { brand } = useAuthContext();
  const [synthUrl, setSynthUrl] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setSynthUrl('');
    setLoading(true);
    try {
      const synthUrl = await playgroundSynthesis(brand as BRAND, text);
      await waitForSynthesisToBeAvailable(synthUrl);
      setSynthUrl(synthUrl);
    } catch (error) {
      NotificationService.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  const handleSSMLTag = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const spacer: string = text.length > 0 ? ' ' : '';

      switch (event.currentTarget.textContent) {
        case 'Englisch':
          setText((prevState) => prevState + spacer + '<lang="en">original</lang>.');
          break;
        case 'Lautschrift':
          setText((prevState) => prevState + spacer + '<spelling="adaption">original</spelling>.');
          break;
        case 'Phonem':
          setText((prevState) => prevState + spacer + '<phonemes="tæɡn">original</phonemes>.');
          break;
        default:
          break;
      }
    },
    [text.length]
  );

  return (
    <section id="Playground">
      <div className="playground-input-area">
        <textarea
          rows={4}
          data-testid="playgroundDrawer-text"
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
        <Button
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={loading || !text || text.length < 3}
          data-testid="playgroundDrawer-submit"
        >
          <AraVoicesLogo variant="white" />
        </Button>
      </div>

      <div className="ssmlTags">
        <h4>SSML Tags</h4>
        <div className="ssmlTags__actions">
          <button className="ssmlTags__button" onClick={(event) => handleSSMLTag(event)} data-testid="ssmlTag-englisch">
            <span>Englisch</span>
          </button>
          <button
            className="ssmlTags__button"
            onClick={(event) => handleSSMLTag(event)}
            data-testid="ssmlTag-lautschrift"
          >
            <span>Lautschrift</span>
          </button>
          <button className="ssmlTags__button" onClick={(event) => handleSSMLTag(event)} data-testid="ssmlTag-phonem">
            <span>Phonem</span>
          </button>
        </div>
      </div>

      <ParrotAudioPlayer
        url={synthUrl}
        downloadable={false}
        isPlaybackRateChangeable={false}
        isPlaygroundPlayer={true}
      />
      {loading ? (
        <Backdrop id="overlay" open={true} data-testid="playgroundDrawer-backdrop">
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </section>
  );
});

export default PlaygroundDrawer;
