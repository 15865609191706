import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import ArticlesDragAndDrop, {
  ArticleToSchedule,
  ScheduledArticle,
} from '../../../components/ArticlesDragAndDrop/ArticlesDragAndDrop';
import { NotificationService } from '../../../components/Notifications/Notifications';
import { BRAND } from '../../../models/Article';
import * as ArticleService from '../../../services/ArticleService';
import { useAuthContext } from '../../../services/Auth/AuthContext';
import { sanitizeArticleCategories } from '../ArticleJobList/ArticleJobList';
import { useArticlesContext } from '../store/ArticlesContext';
import './AlexaUpdateModal.scss';

interface ComponentProps {
  onPublishSuccess: () => void;
}

export default function AlexaUpdateModal({ onPublishSuccess }: ComponentProps) {
  const { brand } = useAuthContext() as { brand: BRAND };
  const { isModalOpen, toggleAlexaUpdateModal, articles, checkedArticleIds, setCheckedArticlesIds } =
    useArticlesContext();
  const [articlesToSchedule, setArticlesToSchedule] = useState<ArticleToSchedule[]>([]);
  const [articlesToPublish, setArticlesToPublish] = useState<ScheduledArticle[]>([]);

  useEffect(() => {
    if (!isModalOpen && !checkedArticleIds.length) return;

    const checkedArticles = checkedArticleIds.map((id) => {
      const scheduledArticle = articlesToSchedule.find((article) => article.id === id);

      if (scheduledArticle) {
        return scheduledArticle;
      }

      const article = articles.find((article) => article.id === id);

      return {
        id: article?.id,
        brand: article?.brand,
        title: article?.title,
        category: sanitizeArticleCategories(article?.categories || []),
        synthOrdinal: article?.synthesis.ordinal as number,
      };
    });

    setArticlesToSchedule(checkedArticles as ArticleToSchedule[]);
  }, [isModalOpen, checkedArticleIds.length]);

  const handleCloseModal = () => {
    setArticlesToPublish([]);
    toggleAlexaUpdateModal(false);
  };

  const handleScheduledArticles = (articles: ScheduledArticle[]) => {
    setArticlesToPublish(articles);
  };

  const scheduleArticles = () => {
    const promise = ArticleService.publishArticlesToAlexa(articlesToPublish, brand)
      .then(() => {
        NotificationService.success('Alexa News Update erfolgreich aktualisiert');
        setCheckedArticlesIds([]);
        handleCloseModal();
        onPublishSuccess();
      })
      .catch((error) => {
        NotificationService.error(error);
      });

    return trackPromise(promise);
  };

  return (
    <Dialog open={isModalOpen} fullWidth maxWidth="md" className="alexaUpdateModal--container">
      <IconButton
        className="close-icon"
        onClick={() => handleCloseModal()}
        disableRipple
        sx={{ alignSelf: 'flex-end' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className="title-header">Alexa News Update</DialogTitle>

      <DialogContent className="alexaUpdateModal--container">
        <ArticlesDragAndDrop articlesSource={articlesToSchedule} onArticlesChanged={handleScheduledArticles} />
      </DialogContent>

      <DialogActions className="alexaUpdateModalActions--container">
        <Button
          className="publishArticles-btn"
          variant="contained"
          size="large"
          sx={{ mx: 'auto' }}
          data-testid="publish-articles-button"
          onClick={scheduleArticles}
        >
          VERÖFFENTLICHEN
        </Button>
      </DialogActions>
    </Dialog>
  );
}
