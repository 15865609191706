import { AxiosRequestConfig } from 'axios';
import { ScheduledArticle } from '../components/ArticlesDragAndDrop/ArticlesDragAndDrop';
import { ArticleStatus, BRAND, IArticle } from '../models/Article';
import { httpClient } from './Http/HttpClient';
import {
  handleHttpErrorCustomMessage,
  handleUnknownHttpError,
  HttpErrorEvent,
  IHttpErrorResponse,
} from './Http/HttpErrorHandlers';

export enum ARTICLE_ERROR_TYPE {
  PUBLISH_FAILED_ARTICLE_STATE = 'PUBLISH_FAILED_ARTICLE_STATE',
  PUBLISH_FAILED_SYNTHESIS_SUPERSEDED = 'PUBLISH_FAILED_SYNTHESIS_SUPERSEDED',
  PUBLISH_FAILED_ARTICLE_ID_NOT_NUMERIC = 'PUBLISH_FAILED_ARTICLE_ID_NOT_NUMERIC',
  PUBLISH_FAILED_UNKNOWN = 'PUBLISH_FAILED_UNKNOWN',
  CREATION_ERROR = 'CREATION_ERROR',
  SYNTH_FETCH_ERROR = 'SYNTH_FETCH_ERROR',
  OBJECT_CONCURRENT_MODIFICATION = 'OBJECT_CONCURRENT_MODIFICATION',
  ARTICLE_LIST_FETCH_ERROR = 'ARTICLE_LIST_FETCH_ERROR',
  FORBIDDEN = 'FORBIDDEN',
}

export const ARTICLE_ERROR_MESSAGE = {
  [ARTICLE_ERROR_TYPE.PUBLISH_FAILED_ARTICLE_STATE]:
    'Artikel konnte nicht veröffentlicht werden, da der Artikel nicht den richtigen Status hat.',
  [ARTICLE_ERROR_TYPE.PUBLISH_FAILED_SYNTHESIS_SUPERSEDED]:
    'Veröffentlichung wurde angehalten, da inzwischen eine neue Synthese erzeugt wurde. ',
  [ARTICLE_ERROR_TYPE.PUBLISH_FAILED_ARTICLE_ID_NOT_NUMERIC]:
    'Artikel konnte nicht veröffentlicht werden, da die article id keine gültige Nummer ist.',
  [ARTICLE_ERROR_TYPE.PUBLISH_FAILED_UNKNOWN]: 'Artikel konnte nicht veröffentlicht werden.',
  [ARTICLE_ERROR_TYPE.CREATION_ERROR]: 'Artikel konnte nicht angelegt werden',
  [ARTICLE_ERROR_TYPE.SYNTH_FETCH_ERROR]: 'Artikel konnte nicht gefunden werden.',
  [ARTICLE_ERROR_TYPE.OBJECT_CONCURRENT_MODIFICATION]:
    'Für den Artikel gibt es ein Update. Bitte lade die Seite neu, um den aktuellen Artikelinhalt zu erhalten',
  [ARTICLE_ERROR_TYPE.ARTICLE_LIST_FETCH_ERROR]:
    'Artikelliste kann nicht geladen werden. Bitte refreshen Sie die Seite.',
  [ARTICLE_ERROR_TYPE.FORBIDDEN]: 'Sie sind nicht berechtigt, diese Aktion auszuführen.',
} as const;

export type CreateArticleParams = {
  brand: BRAND;
  externalId: string;
  title: string;
  text: string;
};

export type FetchArticleListParams = {
  brand: BRAND;
  exclusiveStartKey?: string;
  cancelToken?: AxiosRequestConfig['cancelToken'];
  status?: ArticleStatus | null;
  category?: string | null;
};

export type FetchArticleListResponse = {
  items: IArticle[];
  lastArticleId?: string;
  pageSize: number;
};

export type UpdateArticleParams = {
  text: string;
  title: string;
  articleSourceVersion: number;
};

type PlaygroundSynthResponse = {
  success: boolean;
  url: string;
  took: number;
};

export type GetCategoriesResponse = {
  name: string;
  autoPublish: boolean;
  dayRange: string;
  timeRange: string;
  value: string;
};

export type UpdateCategories = {
  autoPublish: boolean;
  brand: BRAND;
  dayRange: string;
  timeRange: string;
  category: string;
};

export type GetFeatureTogglesResponse = {
  group: 'qc' | 'won-import';
  key: string;
  createdAt: string;
  label: string;
  description: string;
  on: boolean;
};

const ARTICLES_PATH = '/articles';

export const publishArticle = (id: string, expSynthOrdinal: number) => {
  return httpClient
    .post(`${ARTICLES_PATH}/${id}/publish?expectedSynthesisOrdinal=${expSynthOrdinal}`, null)
    .catch((error: IHttpErrorResponse) => {
      const errorKey = error.errorKey as ARTICLE_ERROR_TYPE;

      if (ARTICLE_ERROR_TYPE[errorKey]) {
        const errorMessage = ARTICLE_ERROR_MESSAGE[errorKey];
        return Promise.reject(errorMessage);
      }

      return Promise.reject(handleUnknownHttpError(error, 'Publish Article'));
    });
};

export const publishArticlesToAlexa = (articles: ScheduledArticle[], brand: BRAND) => {
  return httpClient.post(`${ARTICLES_PATH}/alexa-publish`, { articles, brand }).catch((error: IHttpErrorResponse) => {
    const errorKey = error.errorKey as ARTICLE_ERROR_TYPE;

    if (ARTICLE_ERROR_TYPE[errorKey]) {
      const errorMessage = ARTICLE_ERROR_MESSAGE[errorKey];
      return Promise.reject(errorMessage);
    }

    return Promise.reject(handleUnknownHttpError(error, 'Publish Articles to Alexa'));
  });
};

export const deleteArticle = (id: string) => {
  return httpClient.delete(`${ARTICLES_PATH}/${id}`);
};

export const depublishArticle = (id: string) => {
  return httpClient
    .put<void>(`${ARTICLES_PATH}/${id}/depublish`, {})
    .then((res) => res)
    .catch((error: IHttpErrorResponse) => {
      if (error.statusCode === 404 || error.statusCode === 500) {
        return Promise.reject(handleHttpErrorCustomMessage(error));
      }
      return Promise.reject(handleUnknownHttpError(error, 'Depublish Article'));
    });
};

export const createArticle = (article: CreateArticleParams) => {
  return httpClient
    .post<IArticle>(ARTICLES_PATH, article)
    .then((article) => article)
    .catch((error: IHttpErrorResponse) => {
      if (error.errorKey === HttpErrorEvent.ERROR_KEY_OBJECT_DATA_INVALID) {
        return Promise.reject('Validation failed: ' + JSON.stringify(error?.data || {}));
      }
      return Promise.reject(handleUnknownHttpError(error, 'Create Article'));
    });
};

export const playgroundSynthesis = (brand: string, text: string) => {
  return httpClient
    .post<PlaygroundSynthResponse>('/playground', {
      text,
      brand,
    })
    .then((res) => {
      if (!res.url) {
        return Promise.reject({ errorKey: ARTICLE_ERROR_TYPE.CREATION_ERROR });
      }
      return res.url;
    })
    .catch((error: IHttpErrorResponse) => {
      if (ARTICLE_ERROR_TYPE.CREATION_ERROR === error.errorKey) {
        return Promise.reject(ARTICLE_ERROR_MESSAGE.CREATION_ERROR);
      }

      return Promise.reject(handleUnknownHttpError(error, 'Playground Synthesis'));
    });
};

const ARTICLES_SORT_BY = {
  fieldName: 'createdAt',
  ascending: false,
};

export const getArticleList = ({
  brand,
  exclusiveStartKey,
  cancelToken = undefined,
  category = null,
  status = null,
}: FetchArticleListParams) => {
  const params = {
    brand: brand,
    sortBy: `${ARTICLES_SORT_BY.fieldName}:${ARTICLES_SORT_BY.ascending ? 1 : -1}`,
    ...(exclusiveStartKey && { exclusiveStartKey }),
    ...(category && { category: encodeURIComponent(category) }),
    ...(status && { status: encodeURIComponent(status) }),
  };

  return httpClient
    .get<FetchArticleListResponse>(`${ARTICLES_PATH}`, params, cancelToken)
    .then((res) => {
      return {
        items: res.items || [],
        lastArticleId: res.lastArticleId,
        pageSize: res.pageSize,
      };
    })
    .catch((error: IHttpErrorResponse) => {
      if (HttpErrorEvent.CALL_CANCELED === error?.errorKey) {
        return Promise.reject(error.errorMessage);
      }
      if (HttpErrorEvent.NETWORK_ERROR === error?.errorKey) {
        return Promise.reject(ARTICLE_ERROR_MESSAGE.ARTICLE_LIST_FETCH_ERROR);
      }
      return Promise.reject(handleUnknownHttpError(error, 'fetch Article Jobs'));
    });
};

export const getArticleById = (id: string) => {
  return httpClient
    .get<IArticle>(`${ARTICLES_PATH}/${id}`)
    .then((article) => article)
    .catch((error: IHttpErrorResponse) => {
      // If the articleId is bad formatted (e.g. no brand name), then backend returns an error message like: "[IllegalArgument] Brand by id=1000 does not exist"
      if (HttpErrorEvent.OBJECT_NOT_FOUND === error?.errorKey || error?.errorMessage?.includes('[IllegalArgument]')) {
        return Promise.reject(ARTICLE_ERROR_MESSAGE.SYNTH_FETCH_ERROR);
      }

      if (HttpErrorEvent.CALL_CANCELED === error?.errorKey) {
        return Promise.reject(error.errorMessage);
      }
      return Promise.reject(handleUnknownHttpError(error, 'get Article by Id'));
    });
};

const enum UpdateArticleActions {
  UPDATE = 'update',
  SYNTH_AND_PUBLISH = 'synthAndPublish',
  AUTO_PUBLISH = 'autoPublish',
}

const updateArticleActions = (id: string, update: UpdateArticleParams | null, action: UpdateArticleActions) => {
  const params = {
    [UpdateArticleActions.SYNTH_AND_PUBLISH]: '?manualPublishAfterSynth=true',
    [UpdateArticleActions.AUTO_PUBLISH]: '?markArticleAsChecked=true',
    [UpdateArticleActions.UPDATE]: '',
  };

  return httpClient
    .put<IArticle>(`${ARTICLES_PATH}/${id}${params[action]}`, update || {})
    .then((article) => article)
    .catch((error: IHttpErrorResponse) => {
      if (error?.errorKey === HttpErrorEvent.ERROR_KEY_OBJECT_DATA_INVALID) {
        return Promise.reject('Validation failed: ' + JSON.stringify(error?.data || {}));
      }

      const errorKey = error.errorKey as ARTICLE_ERROR_TYPE;
      if (ARTICLE_ERROR_TYPE[errorKey]) {
        const errorMessage = ARTICLE_ERROR_MESSAGE[errorKey];
        return Promise.reject(errorMessage);
      }

      return Promise.reject(handleUnknownHttpError(error, `${action}Article`));
    });
};

export const updateArticle = {
  update: (id: string, update: UpdateArticleParams) => updateArticleActions(id, update, UpdateArticleActions.UPDATE),
  synthAndPublish: (id: string, update: UpdateArticleParams) =>
    updateArticleActions(id, update, UpdateArticleActions.SYNTH_AND_PUBLISH),
  autoPublish: (id: string) => updateArticleActions(id, null, UpdateArticleActions.AUTO_PUBLISH),
};

export const getCategories = (brand: BRAND) => {
  return httpClient
    .get<Array<GetCategoriesResponse>>(`${ARTICLES_PATH}/categories`, { brand })
    .then((res) => res)
    .catch((error: IHttpErrorResponse) => {
      if (error.errorKey) {
        console.error({ error: error });
        return Promise.reject(error.errorMessage);
      }
      return Promise.reject(handleUnknownHttpError(error, 'Get Article Categories'));
    });
};

export const updateCategoryAction = ({ brand, category, autoPublish, dayRange, timeRange }: UpdateCategories) => {
  return httpClient
    .patch<UpdateCategories>(`${ARTICLES_PATH}/categories?brand=${brand}&category=${category}`, {
      autoPublish,
      dayRange,
      timeRange,
    })
    .then((res) => res)
    .catch((error: IHttpErrorResponse) => {
      if (error.statusCode && [400, 404, 500].includes(error.statusCode)) {
        return Promise.reject(handleHttpErrorCustomMessage(error));
      }
      return Promise.reject(handleUnknownHttpError(error, 'update Category action'));
    });
};

export const getFeatureToggles = (brand: BRAND) => {
  return httpClient
    .get<Array<GetFeatureTogglesResponse>>('/feature-toggle', { brand })
    .then((res) => res)
    .catch((error: IHttpErrorResponse) => {
      if (error.statusCode === 500) {
        return Promise.reject(handleHttpErrorCustomMessage(error));
      }
      return Promise.reject(handleUnknownHttpError(error, 'Get Feature Toggles'));
    });
};

export const updateFeatureToggle = (id: string, status: boolean) => {
  return httpClient
    .patch<void>(`/feature-toggle/${id}?value=${status}`, {})
    .then((res) => res)
    .catch((error: IHttpErrorResponse) => {
      if (error.statusCode && [400, 404, 500].includes(error.statusCode)) {
        return Promise.reject(handleHttpErrorCustomMessage(error));
      }
      return Promise.reject(handleUnknownHttpError(error, 'Patch Feature Toggle'));
    });
};
