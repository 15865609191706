import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import { CompositeDecorator, ContentBlock, ContentState, EditorState } from 'draft-js';
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import Prompt from '../Prompt/Prompt';
import { applyAudioHighlighting } from './plugins/applyAudioHighlighting';
import { articleTextEditorContextMenuFactory } from './plugins/EditorContextMenu';
import { editingDecorators, readonlyDecorators } from './plugins/SSMLHighlightDecorator';
import './SSMLTextEditor.scss';

export const ARTICLE_AUDIO_HIGHLIGHT_CSS_CLASS = 'articleTextEditor--playback-highlight';

const audioHighlightBlockStyle = (contentBlock: ContentBlock) => {
  return contentBlock.getType() === 'styled' ? ARTICLE_AUDIO_HIGHLIGHT_CSS_CLASS : '';
};

type SSMLTextEditorProps = {
  text: string;
  readonly: boolean;
  currentAudioPosition?: number;
  onTextChange: (value: string) => void;
  className?: string;
  dataTestId?: string;
};
export const SSMLTextEditor = ({
  text,
  readonly,
  onTextChange,
  currentAudioPosition,
  className = '',
  dataTestId = '',
}: SSMLTextEditorProps) => {
  const loadedRef = useRef(false);

  const { inlineToolbarPlugin, ArticleTextEditorContextMenu: ContextMenu } = useMemo(
    articleTextEditorContextMenuFactory,
    []
  );
  const plugins = [inlineToolbarPlugin] as EditorPlugin[];
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  // Tracks if the field value has been edited by the user
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const wasReadMode = useRef(true);

  const onChangeHandlerEditMode = (newEditorState: EditorState) => {
    const currentChangeType = newEditorState.getLastChangeType();

    if (currentChangeType) {
      onTextChange(newEditorState.getCurrentContent().getPlainText());
      //! The field has been edited
      setIsFieldChanged(true);
    }
    if (loadedRef.current) {
      setEditorState(newEditorState);
    }
  };

  const onChangeHandlerReadonlyMode = (newEditorState: EditorState) => {
    const currentChangeType = newEditorState.getLastChangeType();

    if (currentChangeType === 'change-block-data') {
      setEditorState(newEditorState);
      return;
    }

    const newSelectionState = newEditorState.getSelection();
    const decorator = new CompositeDecorator(readonlyDecorators);
    const newState = EditorState.createWithContent(newEditorState.getCurrentContent(), decorator);

    const newStateWithSelection = EditorState.acceptSelection(newState, newSelectionState);
    setEditorState(newStateWithSelection);
  };

  const updateAudioHighlighterPosition = (currentEditorState: EditorState) => {
    const callback = readonly ? onChangeHandlerReadonlyMode : onChangeHandlerEditMode;
    applyAudioHighlighting({
      audioPosition: currentAudioPosition,
      editorState: currentEditorState,
      onChangeCallback: callback,
    });
  };

  // Warning: DON'T add `editorState` as a dependency. It will cause infinite render
  useEffect(() => {
    if (currentAudioPosition !== undefined && readonly === wasReadMode.current) {
      updateAudioHighlighterPosition(editorState);
    }
  }, [currentAudioPosition]);

  useEffect(() => {
    const editorDecorators = readonly ? readonlyDecorators : editingDecorators;

    const initState = EditorState.createWithContent(ContentState.createFromText(text || ''));
    const decorated = EditorState.set(initState, { decorator: new CompositeDecorator(editorDecorators) });

    if (readonly) {
      //* The field has no unsaved changes
      setIsFieldChanged(false);
    }

    if (currentAudioPosition !== undefined && readonly !== wasReadMode.current) {
      wasReadMode.current = readonly;
      updateAudioHighlighterPosition(decorated);
      return;
    }

    setEditorState(decorated);
  }, [text, readonly]);

  // IMPORTANT!
  //
  // Draftjs does not let through click events when the editor is set in readonly mode.
  // We want to have the click events though in order to display the toolbar.
  //
  //
  // see https://github.com/facebook/draft-js/issues/690
  /**
   * @description Prevents any keyboard/mouse event being fired in `readonly` mode.
   * Only `Copy (ctrl/cmd + c) and Search (ctrl/cmd + f) ` command is allowed
   */
  const preventEditingHandler = (event: KeyboardEvent) => {
    const isCopyOrSearchCommand = (event.metaKey || event.ctrlKey) && (event.key === 'c' || event.key === 'f');

    if (readonly && !isCopyOrSearchCommand) {
      return 'not-handled-command';
    }
    return undefined;
  };

  //* This watches the change of `isFieldChanged` property
  //* Adds the `beforeunload` event handler
  //* Trigger the Confirm dialog if the user tries to navigate away
  useEffect(() => {
    const beforeUnloadCallback = (event: BeforeUnloadEvent) => {
      if (isFieldChanged) {
        //? Cancel the event
        event.preventDefault();
        //* Chrome requires returnValue to be set
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', beforeUnloadCallback);

    return () => window.removeEventListener('beforeunload', beforeUnloadCallback);
  }, [isFieldChanged]);

  useEffect(() => {
    if (!loadedRef.current) {
      loadedRef.current = true;
      return;
    }
  }, []);

  return (
    <>
      <Prompt
        when={isFieldChanged}
        id="articleTextEditor"
        message="Du hast ungespeicherte Änderungen. Möchtest Du die Seite wirklich verlassen?"
      />

      <div
        className={`${className} articleTextEditor-wrapper ${readonly ? 'read-mode' : 'edit-mode'}`}
        data-testid={dataTestId}
      >
        {readonly ? (
          /* in readonly we want to get the click events but nothing else */
          <Editor
            keyBindingFn={preventEditingHandler}
            readOnly={false} // do not use state 'readonly' here!
            onChange={onChangeHandlerReadonlyMode}
            editorState={editorState}
            blockStyleFn={audioHighlightBlockStyle}
            plugins={plugins}
          />
        ) : (
          /* in edit mode we want the full functionality */
          <Editor
            readOnly={false} // do not use state 'readonly' here!
            onChange={onChangeHandlerEditMode}
            editorState={editorState}
            blockStyleFn={audioHighlightBlockStyle}
            plugins={plugins}
          />
        )}
        <ContextMenu />
      </div>
    </>
  );
};
