import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserRouter, createRoutesFromElements, redirect, Route, RouterProvider } from 'react-router-dom';
import { BRAND } from 'src/models/Article';
import ArticleLandingPage from 'src/pages/ArticleLandingPage/ArticleLandingPage';
import Login from 'src/pages/Login/Login';
import LoginSuccess from 'src/pages/Login/LoginSuccess';
import Logout from 'src/pages/Login/Logout';
import NotFound from 'src/pages/NotFound/NotFound';
import { useAuthContext } from 'src/services/Auth/AuthContext';
import Layout from './components/Layout/Layout';
import { LoadingIndicator } from './components/LoadingIndicator/LoadingIndicator';
import { NotificationsContainer } from './components/Notifications/Notifications';
import ArticleDetails from './pages/ArticleDetails/ArticleDetails';
import BrandChooser from './pages/BrandChooser/BrandChooser';
import Cpanel from './pages/Cpanel/Cpanel';
import Dictionary from './pages/Dictionary/Dictionary';
import InfoWithCallToActionPage from './pages/InfoWithCallToActionPage/InfoWithCallToActionPage';
import AuthProvider, { validBrands } from './services/Auth/AuthProvider';
import './styles/main.scss';
import { TtsStyle as ttsStyle } from './styles/theme';

export const contextAwareRouteConfig = (authenticated: boolean, loading: boolean, brand: BRAND | null) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <>
        {!loading && authenticated && brand && (
          <Route id="root" path="/" element={<Layout />}>
            <Route index={true} element={<BrandChooser />} />

            <Route
              path=":brand"
              loader={({ params }) => {
                return !validBrands.includes(params.brand as BRAND) ? redirect('/notfound') : null;
              }}
              element={<ArticleLandingPage />}
            />
            <Route
              path=":brand/:id"
              loader={({ params }) => {
                const pathBrand: BRAND = params.brand as BRAND;
                const idBrand: string = params.id as string;

                return !(validBrands.includes(params.brand as BRAND) && idBrand.startsWith(pathBrand))
                  ? redirect('/notfound')
                  : null;
              }}
              element={<ArticleDetails />}
            />

            <Route
              path=":brand/cpanel"
              loader={({ params }) => {
                return !validBrands.includes(params.brand as BRAND) ? redirect('/notfound') : null;
              }}
              element={<Cpanel />}
            />

            <Route path="dictionary" element={<Dictionary />} />
            <Route path="logout" element={<Logout />} />
            <Route path="notfound" element={<NotFound />} />
          </Route>
        )}
        {!loading && !authenticated && (
          <Route id="root" element={<Layout />}>
            <Route path="login-success" element={<LoginSuccess />} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<Login />} />
          </Route>
        )}

        {loading && <Route element={<InfoWithCallToActionPage />} />}

        {!loading && authenticated && !brand && (
          <Route id="root" element={<Layout />}>
            <Route path="*" element={<BrandChooser />} />
          </Route>
        )}
      </>
    )
  );
};
export const AuthenticationAwareAppPart = () => {
  const { userAuthenticated: authenticated, loading, brand } = useAuthContext();

  return (
    <ThemeProvider theme={ttsStyle}>
      <NotificationsContainer />
      <LoadingIndicator />
      <RouterProvider router={contextAwareRouteConfig(authenticated, loading, brand)} />
    </ThemeProvider>
  );
};

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    // StylesProvider helps to re-order the css applied to components
    // `injectFirst` will add the component styles after material-ui styles
    // Thus we won't need to add `!important` when overriding material-ui styles
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AuthenticationAwareAppPart />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </StyledEngineProvider>
  );
};

export default App;
