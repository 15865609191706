import { Button, CircularProgress, DialogActions } from '@mui/material';
import './CreateEntryModal.scss';
import { isInitNewEntries, isSomeCompleteNewEntries, MAX_NUMBER_ENTRIES, NewEntry } from './createEntryModalReducer';
import ErrorBox from './ErrorBox';
import SuccessBox from './SuccessBox';

export default function CreateEntryModalActions({
  newEntries,
  countCreatedEntries,
  onClearAllEntries,
  onCreateAllEntries,
  isCreatingEntry,
}: {
  newEntries: NewEntry[];
  countCreatedEntries: number;
  onClearAllEntries: () => void;
  onCreateAllEntries: () => void;
  isCreatingEntry: boolean;
}) {
  return (
    <DialogActions className="createEntryModalActions--container">
      <div>
        {newEntries.map((newEntry, index) => (newEntry.error ? <ErrorBox key={index} error={newEntry.error} /> : null))}
        {countCreatedEntries !== 0 ? (
          <SuccessBox
            message={`${countCreatedEntries} ${
              countCreatedEntries === 1 ? 'Eintrag' : 'Einträge'
            } erfolgreich angelegt`}
          />
        ) : null}
      </div>
      <div>
        <Button
          className="clearEntries-btn"
          onClick={() => onClearAllEntries()}
          disabled={isInitNewEntries(newEntries) && newEntries.length === 3}
        >
          ALLE EINTRÄGE LEEREN
        </Button>
        <Button
          className="addEntries-btn"
          onClick={() => onCreateAllEntries()}
          disabled={!isSomeCompleteNewEntries(newEntries)}
        >
          {isCreatingEntry ? <CircularProgress size={20} style={{ color: 'grey' }} /> : 'ALLE EINTRÄGE HINZUFÜGEN'}
        </Button>
      </div>
      <div
        style={{ paddingTop: '30px' }}
      >{`Du kannst bis zu ${MAX_NUMBER_ENTRIES} Einträge gleichzeitig hinzufügen`}</div>
    </DialogActions>
  );
}
