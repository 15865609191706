import { EditorState, Modifier, SelectionState } from 'draft-js';

type applyAudioHighlightingCmd = {
  audioPosition?: number;
  editorState: EditorState;
  onChangeCallback: (newEditorState: EditorState) => void;
};
export const applyAudioHighlighting = ({ audioPosition, editorState, onChangeCallback }: applyAudioHighlightingCmd) => {
  const originalSelection = editorState.getSelection();
  let state = editorState.getCurrentContent();

  editorState
    .getCurrentContent()
    .getBlocksAsArray()
    .forEach((block, index) => {
      let blockStyle = 'unstyled';

      if (audioPosition == index) {
        blockStyle = 'styled';
      }

      if (block.getType() !== blockStyle) {
        state = Modifier.setBlockType(state, SelectionState.createEmpty(block.getKey()), blockStyle);
      }
    });

  const styledState = EditorState.push(editorState, state, 'change-block-data');
  const updatedState = EditorState.acceptSelection(styledState, originalSelection);
  onChangeCallback(updatedState);

  // for testing purposes we return the updatedState
  return updatedState;
};
