import { useCallback, useEffect, useState } from 'react';

type TRemoveFunction = () => void;
type TSetState<T> = (arg: T) => void;

export default function useLocalStorage<T>(key: string | null, initialValue: T): [T, TSetState<T>, TRemoveFunction] {
  const item = key ? window?.localStorage.getItem(key) : null;

  const [storedValue, setStoredValue] = useState(() => {
    try {
      return item ? JSON.parse(item) : initialValue;
    } catch (_) {
      return initialValue;
    }
  });

  useEffect(() => {
    if (item && item !== key) {
      setStoredValue(item);
    }
  }, [item, key]);

  const setValue = (value: TSetState<T> | T) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;

    setStoredValue(value);

    if (key) {
      window?.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
  };

  const remove = useCallback(() => {
    setStoredValue(undefined);

    if (key) {
      localStorage.removeItem(key);
    }
  }, []);

  return [storedValue, setValue, remove];
}
