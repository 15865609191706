import {createTheme, PaletteOptions} from '@mui/material/styles';

export const TtsStyle = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3A5EA7',
    },
    secondary: {
      main: 'rgba(0,0,0,0.6)',
      light: '#676767',
    },
  } as PaletteOptions,
});
