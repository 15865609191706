import { AnimatePresence, motion } from 'framer-motion';
import ParrotAudioPlayer from '../../../components/AudioPlayer/AudioPlayer';
import { BRAND } from '../../../models/Article';

type ArticleAudioPreviewProps = {
  audioLink?: string;
  brand: BRAND;
  onAudioProgress?: (time: number) => void;
};

export const ArticleAudioPreview = ({ audioLink, brand, onAudioProgress = () => {} }: ArticleAudioPreviewProps) => {
  const setOnAudioInProgress = (currentTime: number) => {
    onAudioProgress(currentTime);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1, transition: { duration: 0.3 } }}
        exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
        className="audioWrapper"
        data-testid="article-audio-player"
      >
        <ParrotAudioPlayer url={audioLink} onAudioProgress={setOnAudioInProgress} brand={brand} />
      </motion.div>
    </AnimatePresence>
  );
};
