import { httpClient } from '../Http/HttpClient';

class AuthService {
  static readonly url = '/current-user';
  constructor() {}

  async resumeSession(token: string) {
    httpClient.setAuthorizationHeader(token);
    return this.authenticate(token);
  }

  clearSession() {
    httpClient.unsetAuthorizationHeader();
  }

  async authenticate(token: string) {
    return httpClient
      .get(AuthService.url)
      .then(() => token)
      .catch((e) => Promise.reject(e));
  }
}

export const authService = new AuthService();
