import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { usePromiseTracker } from 'react-promise-tracker';
import './LoadingIndicator.scss';

export const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {promiseInProgress && (
        <Backdrop className="backdrop" open={true} data-testid="loading-indicator">
          <CircularProgress color="inherit" style={{ marginLeft: '66px' }} />
        </Backdrop>
      )}
    </>
  );
};
