export const WeltLogo = () => (
  <svg width="134" height="35" viewBox="0 0 134 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.844 34.2727V8.0543H106.051V0.726929H133.83V8.0543H124.061V34.2727H115.844Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M88.1396 34.2727V0.726929H96.3571V26.9443H110.65V34.2727H88.1396Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.2784 30.0401L82.1727 30.1379C78.5758 33.455 74.2116 35 68.4386 35C58.2663 35 51.4319 28.0083 51.4319 17.6022C51.4319 7.07387 58.0896 0 67.9985 0C77.488 0 83.8869 7.01837 83.9215 17.4646C83.9215 18.4945 83.8521 19.724 83.7512 20.4775H60.2997L60.3796 20.8665C61.3867 25.3334 64.3496 27.6936 68.9471 27.6936C71.9304 27.6936 74.7659 26.6292 77.145 24.6152L77.2512 24.5252L82.2784 30.0401ZM75.298 14.3845L75.2152 14.0016C74.2821 9.68776 71.7625 7.40784 67.9291 7.40784C63.9312 7.40784 61.3304 9.6883 60.4065 14.003L60.3249 14.3845H75.298Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.013 0.726929L36.7656 24.8624L30.2058 0.726929H21.3473L14.7872 24.8141L8.54038 0.726929H0L9.44229 34.2727H19.3574L25.7172 12.5743L32.0772 34.2727H41.9923L51.4335 0.726929H43.013Z"
    />
  </svg>
);
