import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ActionDialogDataType } from '../ArticleJobList/ArticleJobList';
import './ArticleActionDialog.scss';

export const SELECT_ACTIONS = {
  delete: 'deleteSynth',
  depublish: 'depublish',
};

const DIALOG_LABELS_FOR_ACTION = {
  [SELECT_ACTIONS.delete]: {
    title: 'Löschen',
    pendingLabel: 'Artikel werden gelöscht...',
    successLabel: 'Artikel wurden erfolgreich gelöscht.',
    errorLabel: 'Artikel konnten nicht gelöscht werden.',
    errorInfo: 'Artikel mit Status "Veröffentlicht" oder "Synthes läuft" können aktuell nicht gelöscht werden.',
  },
  [SELECT_ACTIONS.depublish]: {
    title: 'Unveröffentlicht',
    pendingLabel: 'Artikel werden unveröffentlicht...',
    successLabel: 'Artikel wurden erfolgreich unveröffentlicht.',
    errorLabel: 'Artikel wurden nicht unveröffentlicht.',
    errorInfo:
      'Artikel mit Status "Synthetisiert" oder "Synthesis läuft" können aktuell nicht unveröffentlicht werden.',
  },
};

type ArticleActionDialogProps = {
  open: boolean;
  selectedAction: string;
  actionDialogData: ActionDialogDataType;
  handleConfirm: () => void;
};
export const ArticleActionDialog = ({
  open,
  handleConfirm,
  selectedAction,
  actionDialogData,
}: ArticleActionDialogProps) => {
  const buildText = () => {
    if (actionDialogData.isRunning) {
      return (
        <section data-testid="dialog-content">
          <span data-testid="dialog-pending" className="success">
            <CircularProgress size="1em" className="check-icon" />{' '}
            {actionDialogData.total + ' ' + DIALOG_LABELS_FOR_ACTION[selectedAction]?.pendingLabel}
          </span>
        </section>
      );
    } else {
      return (
        <section className="dialog-content" data-testid="dialog-content">
          {actionDialogData.successfully > 0 && (
            <span data-testid="dialog-successfully" className="success">
              <CheckCircleIcon className="check-icon" /> {actionDialogData.successfully}{' '}
              {DIALOG_LABELS_FOR_ACTION[selectedAction]?.successLabel}{' '}
            </span>
          )}
          {actionDialogData.failed > 0 && (
            <section className="dialog-content-error">
              <span className="fail">
                <SmsFailedIcon className="fail-icon" /> {actionDialogData.failed}{' '}
                {DIALOG_LABELS_FOR_ACTION[selectedAction]?.errorLabel}{' '}
              </span>
              <span className="fail-info">{DIALOG_LABELS_FOR_ACTION[selectedAction].errorInfo}</span>
            </section>
          )}
        </section>
      );
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{DIALOG_LABELS_FOR_ACTION[selectedAction]?.title}</DialogTitle>
      <DialogContent>
        <DialogContent>{buildText()}</DialogContent>
      </DialogContent>
      <DialogActions>
        {!actionDialogData.isRunning && (
          <Button
            variant="contained"
            disabled={actionDialogData.isRunning}
            onClick={handleConfirm}
            color="primary"
            data-testid="confirm-publish"
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
